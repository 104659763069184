import {useEffect, useState} from "react";
import useEmailPhoneUserVerification from './useEmailPhoneUserVerification';

const usePaddingTimeline = () => {
    const {isShowEmailVerificationAlert, isShowPhoneVerificationAlert} = useEmailPhoneUserVerification();
    const [paddingBottom, setPaddingBottom] = useState(40);

    useEffect(() => {
        // setPaddingBottom(isShowEmailVerificationAlert || isShowPhoneVerificationAlert ? 300 : 31);
    }, [isShowEmailVerificationAlert, isShowPhoneVerificationAlert]);

    return paddingBottom;
};

export default usePaddingTimeline;
