import {
    Skeleton,
} from '@mui/material';
import {
    Form,
    Input,
    DatePicker,
    Button
} from 'antd';
import type {
    FormInstance
} from 'antd/es/form';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { createProject, updateProject } from 'modules/projects/slices/projectsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

interface Project {
    id?: string;
    name: string;
    number: string;
    start_datetime?: Dayjs;
    end_datetime?: Dayjs;
}

interface ServerError {
    message: string;
    errors: {
        [key: string]: string[];
    };
}

export interface ProjectCreateModalProps {
    parent?: string;
    onSubmit?: () => void;
    onClose?: () => void;
}

const ProjectCreateModal = (
    props: ProjectCreateModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.project);
    const [isOpen, setIsOpen] = useState(false);
    const [project, setProject] = useState<Project | undefined>(undefined);
    const formRef = useRef<FormInstance>(null);
    const [serverErrors, setServerErrors] = useState<ServerError | null>(null);

    useEffect(() => {
        if (isOpen) {
            formRef.current?.resetFields();
            setServerErrors(null);
        }
    }, [isOpen]);

    const handleClose = () => {
        setServerErrors(null);
        props.onClose && props.onClose();
    };

    const onSubmit = async (data: any) => {
        try {
            setServerErrors(null);
            if (props.parent) data.parent = props.parent;

            if (data.start_datetime) {
                data.start_datetime = dayjs(data.start_datetime).format('YYYY-MM-DD HH:mm:ss');
            }
            if (data.end_datetime) {
                data.end_datetime = dayjs(data.end_datetime).format('YYYY-MM-DD HH:mm:ss');
            }

            if (project && project.id) {
                data.id = project.id;
                await dispatch(updateProject(data)).unwrap();
            } else {
                await dispatch(createProject(data)).unwrap();
            }

            props.onSubmit && props.onSubmit();
            modal?.current?.close();
        } catch (error: any) {
            if (error.status === 422 && error.data) {
                setServerErrors(error.data);

                const formErrors: { [key: string]: { errors: string[] } } = {};
                Object.entries(error.data.errors).forEach(([field, messages]) => {
                    formErrors[field] = { errors: messages as string[] };
                });
                formRef.current?.setFields(
                    Object.entries(formErrors).map(([field, fieldError]) => ({
                        name: field,
                        errors: fieldError.errors,
                    }))
                );
            } else {
                console.error(error);
            }
        }
    };

    const open = (_projectData: Project | undefined = undefined) => {
        let projectData = _projectData;
        setProject(projectData);
        setServerErrors(null);
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: (project?: Project | undefined) => open(project),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={'Create project'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        onClick={close}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={loading}
                        type="primary"
                        onClick={() => formRef.current?.submit()}
                    >
                        {project && project.id ? 'Update' : 'Create'}
                    </Button>
                </>
            }
        >
            {(isOpen) ?
                <Form
                    ref={formRef}
                    layout="vertical"
                    onFinish={onSubmit}
                    initialValues={{
                        name: 'Untitled Project',
                        number: '0000',
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please input project name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="number"
                        label="Number"
                        rules={[{ required: true, message: 'Please input project number' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="start_datetime"
                        label="Start Date"
                        rules={[{ type: 'object', required: true, message: 'Plesae input start date' }]}
                    >
                        <DatePicker
                            className="w-full"
                            showTime
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                    <Form.Item
                        name="end_datetime"
                        label="End Date"
                        rules={[{ type: 'object', required: true, message: 'Plesae input end date' }]}
                    >
                        <DatePicker
                            className="w-full"
                            showTime
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                </Form>
                : <Skeleton variant="rounded" width={396} height={56} />
            }
        </BaseModal >
    </>;
};

export default forwardRef(ProjectCreateModal);