import { useMemo } from 'react';
import { useAppSelector } from 'utilities/hooks';

export interface UserAllowedProps {
    permissions: string | string[];
    not?: boolean;
    children?: any;
};

export function isAllowed(props: UserAllowedProps, currentTenant: any, currentTeam: any, permissions: any) {
    return true;
//     TODO: resolve
//     if (!currentTenant) return false;
//     if (!permissions) return false;

//     if (typeof props.permissions === 'string') {
//         if (props?.not === true) {
//             return !(permissions?.indexOf(props.permissions) >= 0);
//         }

//         return permissions?.indexOf(props.permissions) >= 0;
//     }

//     let isAllowed = false;
//     for (let i = 0; i < props.permissions.length; i++) {
//         if (permissions?.indexOf(props.permissions[i]) >= 0) {
//             isAllowed = true;
//             break;
//         }
//     }

//     if (props?.not === true) {
//         return !isAllowed;
//     }

//     return isAllowed;
}

const UserAllowed = (props: UserAllowedProps) => {
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { currentTeam } = useAppSelector((state) => state.teams);
    const { permissions } = useAppSelector((state) => state.account);
    const allowed = useMemo(() => isAllowed(props, currentTenant, currentTeam, permissions), [currentTenant, currentTeam, permissions]);

    if (!allowed) return <></>;

    return <>{props?.children}</>;
};

export default UserAllowed;