import {Outlet, useLocation} from 'react-router-dom';
import { useSitemaxUIController } from 'context';
import { Box } from '@mui/material';

import WBox from 'containers/WBox';
import DefaultAppBar from 'components/DefaultAppBar';
import SideNavigation from 'components/SideNavigation';
import {useRef} from 'react';

const TopLayout = () => {
    const [controller] = useSitemaxUIController();
    const location = useLocation();
    const { miniSidenav } = controller;
    const boxRef = useRef<HTMLDivElement>(null);

    return <>
        <SideNavigation />
        <WBox
            ref={boxRef}
            sx={({ breakpoints, transitions, functions: { pxToRem } }: any) => ({
                p: 3,
                position: "relative",
                overflowY: location.pathname.includes('schedule') ? 'none' : 'auto',
                scrollBehavior: 'smooth',
                webkitOverflowScrolling: 'touch',
                webkitTouchCallout: 'none',
                height: '100%',
                minHeight: '100%',

                // [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? pxToRem(80) : pxToRem(274),
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                // },
            })}
        >
            <DefaultAppBar parentRef={boxRef} />
            <WBox
                sx={() => ({
                    mt: 3,
                    p: 3,
                    backgroundColor: '#FFFFFF', // NOTE: TEMP replace with proper theme variables
                    boxShadow: '0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)',
                    borderRadius: '1rem',
                })}
            >
                <Box id="notification-bar"></Box>
                <Outlet />
            </WBox>
        </WBox>
    </>;
};

export default TopLayout;