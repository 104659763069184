import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAllowed = (permissions: string | string[], not?: boolean) => {
    // const [isAllowed, setIsAllowed] = useState(false);
    // TODO: TEMP
    const [isAllowed, setIsAllowed] = useState(true);
    const { permissions: userPermissions } = useAppSelector((state) => state.account);

    // useEffect(() => {
    //     if (!userPermissions) {
    //         setIsAllowed(false);
    //         return;
    //     }

    //     if (typeof permissions === 'string') {
    //         const iAllowed = userPermissions?.indexOf(permissions) >= 0;
    //         setIsAllowed((not === true)? !iAllowed : iAllowed);
    //         return;
    //     }

    //     for (let i = 0; i < permissions.length; i++) {
    //         if (userPermissions?.indexOf(permissions[i]) >= 0) {
    //             setIsAllowed((not === true)? false : true);
    //             return;
    //         }
    //     }

    //     setIsAllowed(false);
    // }, [userPermissions, permissions, not]);

    // console.log('isAllowed', isAllowed);
    return isAllowed;
}