import {
    Container,
} from '@mui/material';
import ControlledGridStack from 'modules/dashboards/components/ControlledGridStack';
import { ControlledGridStackRef } from 'modules/dashboards/components/ControlledGridStack/ControlledGridStack';
import {
    DashboardsContext
} from 'modules/dashboards/interfaces/dashboards';
import {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    fetchDefaultDashboard,
    saveDashboardMetadata
} from 'modules/dashboards/slices/dashboardsActions';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';

const DashboardsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { defaultDashboard } = useAppSelector((state) => state.dashboards);
    const gridStackRef = useRef<ControlledGridStackRef>(null);

    const [items, setItems] = useState<Array<any>>([]);

    useEffect(() => {
        dispatch(fetchDefaultDashboard());
    }, [location, currentTenant]);

    useEffect(() => {
        if (!defaultDashboard) return;
        if (!defaultDashboard.metadata) return;

        setItems(defaultDashboard.metadata);
    }, [defaultDashboard]);

    // const handleAddWidget = () => {};

    // const handleSave = () => {
    //     const metadata = gridStackRef.current?.save();
    //     dispatch(saveDashboardMetadata(Object.assign({ ...defaultDashboard }, {
    //         metadata: metadata,
    //     })));
    // };

    return <Container component="main" maxWidth={false}>
        {/* <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}> */}
            {/* <Button onClick={handleAddWidget}>Add Widget</Button> */}
            {/* <Button onClick={handleSave}>Save</Button> */}
        {/* </Stack> */}
        <DashboardsContext.Provider value={defaultDashboard}>
            <ControlledGridStack
                ref={gridStackRef}
                items={items}
                options={{
                    float: false,
                    sizeToContent: false,
                    cellHeight: '50px',
                }}
                sx={{
                    mt: 2,
                }}
            />
        </DashboardsContext.Provider>
    </Container>;
};

export default DashboardsPage;