import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'antd';
import {useAppDispatch} from '../../../../utilities/hooks';
import {setterSchedule} from '../../../../slices/scheduleSlice';
import {DateTime} from 'luxon';
import {
    COLOR_ITEM,
    FORMAT_DATE_TIME,
    ScheduleItemType,
    FORMAT_DATE_FOR_SAVE,
    FieldProject,
    FieldStartDate, FieldEndDate
} from 'smx-components';
import dayjs from 'dayjs';

type NewItemType = {
    projectId: string,
    startDate: string,
    endDate: string
};

const ButtonAddScheduleItem = () => {
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm<NewItemType>();

    useEffect(() => {
        form.setFieldsValue({
            startDate: dayjs(DateTime.now().toFormat(FORMAT_DATE_TIME)) as any,
            endDate: dayjs(DateTime.now().plus({days: 3}).toFormat(FORMAT_DATE_TIME)) as any,
        });
    }, [isModalOpen]);

    const onCLickAdd = () => {
        setIsModalOpen(true);
    };

    const addNewItem = (values: NewItemType) => {
        const newItem = {
            color: COLOR_ITEM,
            start_datetime: DateTime.fromISO((values?.startDate as any).format(FORMAT_DATE_FOR_SAVE)).toFormat(FORMAT_DATE_TIME),
            end_datetime: DateTime.fromISO((values?.endDate as any).format(FORMAT_DATE_FOR_SAVE)).toFormat(FORMAT_DATE_TIME),
            project_id: values.projectId,
            completion_percentage: 0,
        } as ScheduleItemType;

        dispatch(setterSchedule({triggerForNewItem: newItem}));
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            addNewItem(values);
            setIsModalOpen(false);
        } catch (errorInfo) {
            console.log('Validation error:', errorInfo);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return <>
        <Button type={'default'} onClick={onCLickAdd} >Add Schedule Item</Button>
        <Modal title="Add new schedule item" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} zIndex={2000} width={300}>
            <Form
                name="newScheduleItemFrom"
                layout="vertical"
                form={form}
                initialValues={{remember: true}}
                autoComplete="off"

            >
                <FieldProject required />
                <FieldStartDate required />
                <FieldEndDate required />
            </Form>
        </Modal>
    </>;
};

export default ButtonAddScheduleItem;
