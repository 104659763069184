import React from "react";
import { useAppDispatch } from "../../../../utilities/hooks";
import ButtonAddScheduleItem from "./buttonAddScheduleItem";

const ControlEditMode = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <ButtonAddScheduleItem />
    </>
  );
};

export default ControlEditMode;
