import {
    LoadingButton,
    TabContext,
    TabList,
    TabPanel
} from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Tab,
    TextField
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { nanoid } from '@reduxjs/toolkit';
import ContactsAutocomplete from 'components/ContactsAutocomplete';
import UsersAutocomplete from 'components/UsersAutocomplete';
import { TeamMemberRole, TeamMemberRoleOptions, TeamType } from 'interfaces/teams';
import { User, Users } from 'interfaces/users';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { fetchCompanies, fetchContacts } from 'slices/contactsActions';
import { fetchUsersForInvitation } from 'slices/invitationsActions';
import { SnackbarTypes, addSnackbar } from 'slices/snackbarsSlice';
import { reset as resetTeam } from 'slices/teamSlice';
import { createTeam, fetchTeams } from 'slices/teamsActions';
import { fetchUsers } from 'slices/usersActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface TeamCreateModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const TeamCreateModal = (
    props: TeamCreateModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading: teamLoading, error: teamError } = useAppSelector((state) => state.team);
    const { loading: usersLoading, error: usersError} = useAppSelector((state) => state.users);
    const { loading: contactsLoading, error: contactsError } = useAppSelector((state) => state.contacts);
    const { register, handleSubmit, reset } = useForm();
    const [invitationsSelected, setInvitationsSelected] = useState<any[]>([]);
    const [contactsSelected, setContactsSelected] = useState<any[]>([]);
    const [membersSelected, setMembersSelected] = useState<any[]>([]);
    const [invitationRole, setInvitationRole] =  useState(TeamMemberRole.VIEWER);
    const [contactRole, setContactRole] =  useState(TeamMemberRole.VIEWER);
    const [role, setRole] = useState(TeamMemberRole.VIEWER);
    const [isOpen, setIsOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState('1');
    const [contactCompany, setContactCompany] = useState<any>();
    const formId = 'team_create_form_' + nanoid();

    useEffect(() => {
        if (isOpen) {
            reset({
                name: 'Untitled Shared Folder',
            });
        }
    }, [isOpen]);

    const onDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchUsers({search: value}))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const onInvitationsDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchUsersForInvitation(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const onContactsDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchContacts({
            search: value,
            parent: contactCompany?.id,
        }))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const onCompaniesDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchCompanies(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const resetDefaults = () => {
        setRole(TeamMemberRole.VIEWER);
        setMembersSelected([]);
        setInvitationRole(TeamMemberRole.VIEWER);
        setInvitationsSelected([]);
        setContactRole(TeamMemberRole.VIEWER);
        setContactsSelected([]);
    };

    const onSubmit = (_data: any) => {
        let action: any;
        let data: any = {
            name: _data.name,
            type: TeamType.CUSTOM,
        };

        if (currentTab === '1') {
            data.members = membersSelected.map((member: User) => {
                return {
                    id: member.id,
                    role: role,
                }
            });
            action = createTeam(data);
        }

        if (currentTab === '2') {
            data.invitations = invitationsSelected.map((invitation: any) => {
                return {
                    id: invitation.id,
                    email: invitation.email,
                    phone: invitation.phone,
                    team_role: invitationRole,
                }
            });
            if (data.invitations.length === 0) {
                dispatch(addSnackbar({
                    type: SnackbarTypes.ERROR,
                    message: 'Please, add more contacts for invitation.'
                }));
                return;
            }
            action = createTeam(data);
        }

        if (currentTab === '3') {
            data.invitations = contactsSelected.map((contact: any) => {
                return {
                    id: contact.id,
                    email: contact.email,
                    phone: contact.phone,
                    team_role: contactRole,
                }
            });
            if (data.invitations.length === 0) {
                dispatch(addSnackbar({
                    type: SnackbarTypes.ERROR,
                    message: 'Please, add more contacts for invitation.'
                }));
                return;
            }
            action = createTeam(data);
        }

        dispatch(action)
            .unwrap()
            .then(() => {
                dispatch(fetchTeams());
                props.onSubmit && props.onSubmit();
                close();
            }).catch(() => {});
    };

    const handleMembersSelected = (members: Users) => {
        setMembersSelected(members);
    };

    const handleRoleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value as TeamMemberRole);
    };

    const handleInvitationsSelected = (invitations: any[]) => {
        setInvitationsSelected(invitations);
    };

    const handleInvitationRoleChange = (event: SelectChangeEvent) => {
        setInvitationRole(event.target.value as TeamMemberRole);
    };

    const handleContactsSelected = (contacts: any[]) => {
        setContactsSelected(contacts);
    };

    const handleContactRoleChange = (event: SelectChangeEvent) => {
        setContactRole(event.target.value as TeamMemberRole);
    };

    /**
     * Modal
     */
    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleOnClose = () => {
        dispatch(resetTeam());
        props.onClose && props.onClose();
    };

    return <>
        <BaseModal
            ref={modal}
            title={'Create Shared Folder'}
            maxWidth={'md'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleOnClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        loading={teamLoading}
                        form={formId}
                        color="primary"
                        disableElevation
                    >
                        Create
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <Stack spacing={2}>
                    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            id="name"
                            variant="outlined"
                            label="Project Name"
                            {...register("name")}
                            error={!!teamError?.errors?.name}
                            helperText={teamError?.errors?.name?.[0]}
                            autoFocus
                            fullWidth
                            required
                        />
                    </form>
                    <TabContext value={currentTab}>
                        <TabList onChange={(event: React.SyntheticEvent, tabIndex: string) => {
                            setCurrentTab(tabIndex);
                            resetDefaults();
                        }}>
                            <Tab
                                label={"Add members"}
                                value="1"
                            />
                            <Tab
                                label={"Send Invitations"}
                                value="2"
                            />
                            <Tab
                                label={"Invite Contacts"}
                                value="3"
                            />
                        </TabList>
                        <TabPanel value="1" sx={{p: 0}}>
                            <Stack direction="row" spacing={2}>
                                <UsersAutocomplete
                                    label="Add people"
                                    onDebounce={onDebounce}
                                    onSelect={handleMembersSelected}
                                    loading={usersLoading}
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={role}
                                        onChange={handleRoleChange}
                                    >
                                        {TeamMemberRoleOptions.map((option: any) => {
                                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                                        })}
                                    </Select>
                                    <FormHelperText>{}</FormHelperText>
                                </FormControl>
                            </Stack>
                        </TabPanel>
                        <TabPanel value="2" sx={{p: 0}}>
                            <Stack direction="row" spacing={2}>
                                <UsersAutocomplete
                                    label="Add by email or phone number"
                                    onDebounce={onInvitationsDebounce}
                                    onSelect={handleInvitationsSelected}
                                    loading={usersLoading}
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={invitationRole}
                                        onChange={handleInvitationRoleChange}
                                    >
                                        {TeamMemberRoleOptions.map((option: any) => {
                                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </TabPanel>
                        <TabPanel value="3" sx={{p: 0}}>
                            <Stack direction="row" spacing={2}>
                                <ContactsAutocomplete
                                    label="Find company"
                                    debounceOnOpen={true}
                                    onDebounce={onCompaniesDebounce}
                                    onChange={(value: any) => {
                                        setContactCompany(value);
                                    }}
                                    loading={contactsLoading}
                                />
                                <UsersAutocomplete
                                    label="Add contacts"
                                    debounceOnOpen={true}
                                    onDebounce={onContactsDebounce}
                                    onSelect={handleContactsSelected}
                                    loading={contactsLoading}
                                    dependent={contactCompany}
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={contactRole}
                                        onChange={handleContactRoleChange}
                                    >
                                        {TeamMemberRoleOptions.map((option: any) => {
                                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </TabPanel>
                    </TabContext>
                </Stack>
                : <Skeleton variant="rounded" width={396} height={56} />
            }
        </BaseModal>
    </>;
};

export default forwardRef(TeamCreateModal);