import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    fetchDashboards,
    fetchDefaultDashboard,
} from './dashboardsActions';

interface DashboardsState {
    dashboards: any[];
    defaultDashboard: any | null;
    loading: boolean;
    error: any | null;
};

export const initialState: DashboardsState = {
    dashboards: [],
    defaultDashboard: null,
    loading: false,
    error: null
};

const dashboardsSlice = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchDashboards.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDashboards.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
            if (!action.payload) return;
            if (!action.payload.data) return;
            state.dashboards = action.payload.data;
        })
        builder.addCase(fetchDashboards.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (!action.payload) return;
            if (!action.payload.data) return;
            state.error = action.payload.data;
        })

        builder.addCase(fetchDefaultDashboard.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDefaultDashboard.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
            if (!action.payload) return;
            if (!action.payload.data) return;
            state.defaultDashboard = action.payload.data;
        })
        builder.addCase(fetchDefaultDashboard.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (!action.payload) return;
            if (!action.payload.data) return;
            state.error = action.payload.data;
        })
    }
});

export const { reset } = dashboardsSlice.actions;
export default dashboardsSlice.reducer;