import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from 'utilities/axios';

export const fetchDashboards = createAsyncThunk('dashboards/fetch', async (_, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/dashboards`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchDefaultDashboard = createAsyncThunk('team/fetchDefault', async (_, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/dashboards/default`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const saveDashboardMetadata = createAsyncThunk('dashboards/metadata', async (data: {id: string, name: string, metadata: any}, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/dashboards/${data.id}/metadata`, data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchDashboardWidgetData = createAsyncThunk('dashboards/widget/data', async (data: {dashboard: string, widget: string}, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/dashboards/${data.dashboard}/widgets/${data.widget}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const exportDashboardWidgetData = createAsyncThunk('dashboards/widget/export', async (data: {dashboard: string, widget: string}, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/dashboards/${data.dashboard}/widgets/${data.widget}/export`, { responseType: 'blob' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${data.widget}_export.csv`); // Adjust the file name and extension as needed
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        return { success: true };
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});