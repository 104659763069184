import {
    Box,
    Container,
    Stack,
    Typography
} from '@mui/material';
import { Navigate } from "react-router-dom";

const RedirectRoute = () => {

    return <Container component="main" disableGutters>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Stack direction="column" spacing={3} sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Redirecting...</Typography>
                {
                    <Navigate to="/documents" />
                }
            </Stack>
        </Box>
    </Container>;
};

export default RedirectRoute;
