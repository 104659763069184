import React from 'react';
import { Grid } from '@mui/material';
import { EntityContext, TeamsContext } from 'interfaces/teams';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import DocumentsTable from '../components/DocumentsTable';
import { useDocumentsPage } from 'hooks/useDocumentsPage';
import TeamCreateModal from 'modals/TeamCreateModal';
import TeamRenameModal from 'modals/TeamRenameModal';
import TeamMembersUpdateModal from 'modals/TeamMembersUpdateModal';
import DefaultTeamMembersUpdateModal from 'modals/DefaultTeamMembersUpdateModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import GuestTeamCreateModal from 'modals/GuestTeamCreateModal';
import GuestTeamMembersUpdateModal from 'modals/GuestTeamMembersUpdateModal';
import CategoriesTreeModal from 'modals/CategoriesTreeModal';

const SharedDocumentsPage = () => {
    const {
        categoryId,
        currentTeam,
        list,
        breadcrumbs,
        loading,
        currentEntity,
        teamCreateModal,
        teamMembersUpdateModal,
        teamRenameModal,
        confirmTeamDeleteModal,
        confirmDeleteBreadcrumbModal,
        guestTeamCreateModal,
        guestTeamMembersUpdateModal,
        moveRowModal,
        fetchData,
        handleConfirmTeamDelete,
        moveCurrentBreadcrumb,
        handleConfirmBreadcrumbDelete,
        navigateTo,
    } = useDocumentsPage({ isShared: true }); // Pass true to indicate shared documents { isShared: true }

    const defaultTeamMembersUpdateModal = React.useRef<React.RefObject<any>>(null);

    return (
        <>
            <Grid item xs={8} sx={{ pt: 2, pb: 3 }}>
                <CustomBreadCrumbs
                    title={currentTeam ? currentTeam.name : 'Shared Documents'}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => {
                        navigateTo();
                    }}
                    onClick={(breadcrumb) => {
                        navigateTo(breadcrumb.id);
                    }}
                />
            </Grid>
            <DocumentsTable list={list} categoryId={categoryId} goTo={navigateTo} fetchData={fetchData} shared={true}/>

            <EntityContext.Provider value={currentEntity}>
                <GuestTeamCreateModal
                    ref={guestTeamCreateModal}
                    onClose={fetchData}
                />
                <GuestTeamMembersUpdateModal
                    ref={guestTeamMembersUpdateModal}
                    onClose={fetchData}
                />
            </EntityContext.Provider>

            <ConfirmationModal
                ref={confirmTeamDeleteModal}
                text='Are you sure you want to delete this team?'
                onConfirm={handleConfirmTeamDelete}
            />
            <ConfirmationModal
                ref={confirmDeleteBreadcrumbModal}
                text='Are you sure you want to delete this item?'
                onConfirm={handleConfirmBreadcrumbDelete}
            />

            <TeamsContext.Provider value={currentTeam}>
                <TeamCreateModal
                    ref={teamCreateModal}
                    onClose={fetchData}
                />
                <TeamRenameModal
                    ref={teamRenameModal}
                    onClose={fetchData}
                />
                <TeamMembersUpdateModal
                    ref={teamMembersUpdateModal}
                    onClose={fetchData}
                />
                <DefaultTeamMembersUpdateModal
                    ref={defaultTeamMembersUpdateModal}
                    onClose={fetchData}
                />
            </TeamsContext.Provider>

            <CategoriesTreeModal
                ref={moveRowModal}
                title={`Move category to...`}
                submitLabel='Move'
                onSubmit={moveCurrentBreadcrumb}
            />
        </>
    );
};

export default SharedDocumentsPage;
