import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ProjectDashboardsPage = () => {
    return <Content>
        Project Dashboard
    </Content>;
};

export default ProjectDashboardsPage;