import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ScheduleViewType} from '../types';
import {CalendarScaleType, ItemTaskType, ScheduleItemType} from 'smx-components';

export interface IScheduleState {
  activeCalendarPage: CalendarScaleType,
  view: ScheduleViewType,
  triggerForNewTask?: ItemTaskType,
  triggerForNewItem?: ScheduleItemType,
}

const initialState: IScheduleState = {
  activeCalendarPage: 'list',
  view: 'gant',
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setterSchedule: (state, action: PayloadAction<Partial<IScheduleState>>) => ({ ...state, ...action.payload }),
  },
});

export const {
  setterSchedule,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
