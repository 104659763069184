import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from 'utilities/axios';

export const fetchClassifications = createAsyncThunk('classifications/fetch', async (search: null | string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        let url = `api/v1/classifications`;
        if (search) url = `${url}?search=${search}`;

        const response = await axios.get(url);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const createClassification = createAsyncThunk('classifications/create', async (data: { name: string, code: string }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/classifications`, data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updateClassification = createAsyncThunk('classification/update', async (data: {id: string, name: string, code: string}, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.put(`api/v1/classifications/${data.id}`, data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const deleteClassification = createAsyncThunk('classification/delete', async (id: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.delete(`api/v1/classifications/${id}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});
