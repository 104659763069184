export enum REQUEST_CODES {
    OK = 200,
    CREATED = 201,
    UNAUTHORIZED = 401,
    CANCELED = 'ERR_CANCELED',
};

export const mimeTypes = {
    image: [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
        'image/x-icon',
        'image/svg+xml',
        'image/webp',
        'image/vnd.microsoft.icon',
        'image/vnd.wap.wbmp',
        'image/x-xbitmap',
    ],
    pdf: [
        'application/pdf',
        'application/x-pdf',
        'application/acrobat',
        'applications/vnd.pdf',
        'text/pdf',
        'text/x-pdf',
        'application/x-bzpdf',
        'application/x-gzpdf',
        'application/x-xzpdf',
    ],
    txt: [
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.text',
    ],
    xsl: [
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.oasis.opendocument.spreadsheet',
    ],
    pwp: [
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.oasis.opendocument.presentation',
    ],
    media: [
        'audio/mpeg',
        'audio/wav',
        'audio/x-wav',
        'audio/x-aiff',
        'audio/x-flac',
        'audio/x-matroska',
        'audio/x-ms-wma',
        'audio/x-vorbis+ogg',
        'audio/x-ms-wax',
        'video/mp4',
        'video/x-matroska',
        'video/x-ms-wmv',
        'video/x-msvideo',
        'video/x-flv',
        'video/x-f4v',
        'video/x-m4v',
        'video/x-ms-asf',
    ],
    zip: [
        'application/zip',
        'application/x-rar-compressed',
        'application/x-7z-compressed',
        'application/x-tar',
        'application/x-gzip',
        'application/x-bzip',
        'application/x-bzip2',
        'application/x-xz',
        'application/x-lzip',
        'application/x-lzma',
        'application/x-lzop',
        'application/x-lz4',
        'application/x-freearc',
        'application/x-ace-compressed',
        'application/x-arj',
        'application/x-cfs-compressed',
        'application/x-stuffit',
        'application/x-stuffitx',
        'application/x-gca-compressed',
        'application/x-lzh',
        'application/vnd.rar',
    ],
};

export const allowedExtensions = [
    '.pdf',
    '.fdf',
    '.xfdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.pub',
    '.dwg',
    '.dxf',
    '.dgn',
    '.rvt',
    '.dwf',
    '.rtf',
    '.odt',
    '.ods',
    '.odp',
    '.wpf',
    '.bmp',
    '.wmf',
    '.emf',
    '.gif',
    '.hdp',
    '.jpg',
    '.jp2',
    '.jpc',
    '.png',
    '.jpeg',
    '.tif',
    '.tiff',
    '.html',
    '.mht',
    '.svg',
    '.mp4',
    '.ogg',
    '.webm',
    '.msg',
    '.ifc',
    '.rvt',
    '.xps',
    '.oxps',
    '.xml',
    '.xod',
    '.xaml',
    '.zip',
    'image/*',
    'application/pdf'
];