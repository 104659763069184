import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Skeleton,
    TextField
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { Phase } from 'modules/settings/interfaces/phases';
import { createPhase, updatePhase } from 'modules/settings/slices/phasesActions';

export interface PhaseModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const PhaseModal = (
    props: PhaseModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.phases);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [phase, setPhase] = useState<Phase | undefined>(undefined);
    const formId = 'phase_create_form_' + nanoid();

    useEffect(() => {
        if (isOpen) {
            reset(phase);
        }
    }, [isOpen]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const onSubmit = (data: any) => {
        if (phase && phase.id) {
            data.id = phase.id;
            dispatch(updatePhase(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        } else {
            dispatch(createPhase(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        }
    };

    const open = (phase: Phase | undefined = undefined) => {
        if (phase) {
            setPhase(phase);
        } else {
            setPhase({
                name: '',
                code: '',
            });
        }
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: (phase?: Phase | undefined) => open(phase),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={'Create cost code'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        { phase && phase.id ? 'Update' : 'Create' }
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        id="name"
                        variant="outlined"
                        label="Name"
                        {...register("name")}
                        error={!!error?.errors?.name}
                        helperText={error?.errors?.name?.[0]}
                        autoFocus
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        id="code"
                        variant="outlined"
                        label="Code"
                        {...register("code")}
                        error={!!error?.errors?.code}
                        helperText={error?.errors?.code?.[0]}
                        autoFocus
                        fullWidth
                        required
                    />
                </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(PhaseModal);
