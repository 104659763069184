import {
    Button,
    Typography
} from '@mui/material';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

export interface ConfirmationModalProps {
    title?: string;
    text?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    onCancel?: () => void;
    onConfirm?: (value: Boolean) => void;
    onClose?: () => void;
};

const ConfirmationModal = (
    props: ConfirmationModalProps,
    ref: React.Ref<unknown>
) => {
    // const {text, confirmLabel, cancelLabel } = props;
    // let { onCancel, onConfirm, onClose } = props;
    const [title, setTitle] = useState(props.title);
    const [text, setText] = useState(props.text);
    const [confirmLabel, setConfirmLabel] = useState(props.confirmLabel);
    const [cancelLabel, setCancelLabel] = useState(props.cancelLabel);
    const [onCancel, setOnCancel] = useState<(() => void) | undefined>(props.onCancel);
    // const [onConfirm, setOnConfirm] = useState<((value: Boolean) => void) | undefined>(props.onConfirm);
    const [onClose, setOnClose] = useState<(() => void) | undefined>(props.onClose);
    const modal = useRef<BaseModalRef>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        onClose && onClose();
    };

    const handleConfirm = () => {
        props.onConfirm && props.onConfirm(true);
        close();
    };

    const handleCancel = () => {
        onCancel && onCancel();
        close();
    };

    const open = (newProps?: ConfirmationModalProps) => {
        if (newProps?.title) setTitle(newProps?.title);
        if (newProps?.text) setText(newProps?.text);
        if (newProps?.confirmLabel) setConfirmLabel(newProps?.confirmLabel);
        if (newProps?.cancelLabel) setCancelLabel(newProps?.cancelLabel);
        if (newProps?.onCancel) setOnCancel(newProps?.onCancel);
        if (newProps?.onConfirm) newProps.onConfirm(true);
        // if (newProps?.onConfirm) setOnConfirm(newProps?.onConfirm);
        if (newProps?.onClose) setOnClose(newProps?.onClose);
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: (newProps?: ConfirmationModalProps) => open(newProps),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={title || 'Confirmation'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleCancel()}
                    >
                        {cancelLabel || 'Cancel'}
                    </Button>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleConfirm()}
                    >
                        {confirmLabel || 'Confirm'}
                    </Button>
                </>
            }
        >
            <Typography>{text}</Typography>
        </BaseModal>
    </>;
};

export default forwardRef(ConfirmationModal);