import { Calendar, CalendarScaleType, Gant, ItemTaskType, ScheduleItemType, CalendarTable } from 'smx-components';
import usePaddingTimeline from '../../../../hooks/usePaddingTimeline';
import { Carousel, Flex } from 'antd';
import ButtonScale from './buttonScale';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ScheduleViewType } from '../../../../types';
import { useEffect, useMemo, useRef } from 'react';
import ButtonAddTask from './buttonAddTask';
import { useAppDispatch } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import ControlEditMode from './controlEditMode';

const SchedulePage = () => {
    const dispatch = useAppDispatch();
    const paddingBottom = usePaddingTimeline();
    const activeCalendarPage = useSelector<RootState, CalendarScaleType>((state) => state.schedule.activeCalendarPage);
    const triggerForNewItem = useSelector<RootState, ScheduleItemType | undefined>(
        (state) => state.schedule.triggerForNewItem,
    );
    const triggerForNewTask = useSelector<RootState, ItemTaskType | undefined>(
        (state) => state.schedule.triggerForNewTask,
    );
    const view = useSelector<RootState, ScheduleViewType>((state) => state.schedule.view);
    const carouselRef = useRef<any>();

    const gant = useMemo(
        () => <Gant paddingBottom={paddingBottom} newItem={triggerForNewItem} authorId={'dddddd'} />,
        [triggerForNewItem],
    );
    const calendar = useMemo(
        () => <Calendar paddingBottom={paddingBottom} activePage={activeCalendarPage} newTask={triggerForNewTask} />,
        [activeCalendarPage, triggerForNewTask],
    );

    useEffect(() => {
        if (triggerForNewItem) {
            dispatch(setterSchedule({ triggerForNewItem: undefined }));
        }
    }, [triggerForNewItem]);

    useEffect(() => {
        if (triggerForNewTask) {
            dispatch(setterSchedule({ triggerForNewTask: undefined }));
        }
    }, [triggerForNewTask]);

    const getNumberSlide = () => {
        if (view === 'gant') return 0;
        if (view === 'calendar' && activeCalendarPage === 'list') return 1;
        if (view === 'calendar' && activeCalendarPage === 'agenda') return 2;
        if (view === 'calendar' && activeCalendarPage === 'day') return 3;
        return 0;
    };

    useEffect(() => {
        const newCurrentSlide = getNumberSlide();
        carouselRef.current.goTo(newCurrentSlide);
    }, [activeCalendarPage, view]);

    return (
        <Flex vertical>
            <Flex justify={'space-between'}>
                {view === 'calendar' && <ButtonAddTask />}
                {view === 'gant' && <ControlEditMode />}

                <ButtonScale />
            </Flex>
            <Carousel ref={carouselRef} dots={false} lazyLoad={'progressive'} effect="fade">
                <div>{gant}</div>
                <div>
                    <div style={{ marginTop: 10 }}>
                        <CalendarTable />
                    </div>
                </div>
                <div>{view === 'calendar' && activeCalendarPage === 'agenda' && calendar}</div>
                <div>{view === 'calendar' && activeCalendarPage === 'day' && calendar}</div>
                <div>{calendar}</div>
            </Carousel>
        </Flex>
    );
};

export default SchedulePage;
