import {
    LinearProgress,
    Stack
} from '@mui/material';
import {
    DataGridPro,
    DataGridProProps,
} from '@mui/x-data-grid-pro';

interface DefaultDataGridProps extends DataGridProProps {
    height?: number;
}

const DefaultDataGrid = (props: DefaultDataGridProps) => {
    const { height } = props;
    const EmptyRowsOverlay = () => {
        return <Stack height="100%" alignItems="center" justifyContent="center">No Data</Stack>;
    };

    const handleStateChange = (state: any) => {
        // TODO temp solution should be replaced with a real key
        const elements: NodeListOf<HTMLDivElement> = document.querySelectorAll('div');
        elements.forEach((element: HTMLDivElement) => {
            if (element.textContent === 'MUI X Invalid license key') {
                const previousSibling = element.previousElementSibling as HTMLElement;
                if (previousSibling?.classList.contains('MuiDataGrid-virtualScroller')) {
                    element.style.color = 'transparent';
                    element.style.backgroundColor = 'transparent';
                }
            }
        });
    };

    return <div style={height ? { height, width: '100%' } : { width: '100%' }}>
        <DataGridPro
            autoHeight={!height}
            // density="comfortable"
            slots={{
                loadingOverlay: LinearProgress,
                noRowsOverlay: EmptyRowsOverlay
            }}
            disableVirtualization={props.disableVirtualization}
            sx={{
                '& .MuiDataGrid-columnHeaders .MuiCheckbox-root': {
                    visibility: 'hidden',
                },
                '& .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked': {
                    visibility: 'visible',
                },
                '& .MuiDataGrid-columnHeaders:hover .MuiCheckbox-root': {
                    visibility: 'visible',
                },
                '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
                    visibility: 'hidden',
                },
                '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-checked': {
                    visibility: 'visible',
                },
                '& .MuiDataGrid-row:hover .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
                    visibility: 'visible',
                },

                '& .MuiDataGrid-row .hidden': {
                    visibility: 'hidden',
                },
                '& .MuiDataGrid-row:hover .hidden': {
                    visibility: 'visible',
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                },
            }}
            onStateChange={handleStateChange}
            {...props}
        />
    </div>
};

export default DefaultDataGrid;