import {
    Close,
    Description,
    ErrorOutline,
    ExpandLess,
    ExpandMore,
    OpenInNew
} from '@mui/icons-material';
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import { createPortal } from 'react-dom';
import {
    reset,
    resetCounters,
} from 'slices/progressSlice';
import { addSnackbar } from 'slices/snackbarsSlice';
import { fileSizeFormat } from 'utilities';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface ProgressCardRef {
    open: () => void;
    close: () => void;
};

export interface ProgressCardProps {
    title?: String,
    setOpen?: (value: boolean) => void;
    onOpenClick?: (value: any) => void;
};

const ProgressCard = (
    props: ProgressCardProps,
    ref: React.Ref<unknown>,
) => {
    const dispatch = useAppDispatch();
    const { title, setOpen, onOpenClick } = props;
    const { isCalm, total, completed, succeed, failed, processing, items } = useAppSelector((state) => state.progress);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [currentTitle, setCurrentTitle] = useState(title);

    useEffect(() => {
        if (!isCalm) {
            setIsOpen(true);
        }
    }, [isCalm]);

    useEffect(() => {
        (async () => {
            if (!succeed && !failed) return;

            setIsExpanded(false);
            if (succeed > 0) {
                dispatch(addSnackbar({
                    message: 'Uploaded ' + succeed + ' files successfully',
                    type: 'success'
                }));
            }
            if (failed > 0) {
                dispatch(addSnackbar({
                    message: 'Uploaded ' + failed + ' files unsuccessfully',
                    type: 'error'
                }));
            }
            dispatch(resetCounters());
        })();
    }, [succeed, failed]);

    useEffect(() => {
        if (total > 0) {
            if (completed == 1) {
                setCurrentTitle(`${completed} item completed`);
            } else if (completed > 1) {
                setCurrentTitle(`${completed} items completed`);
            } else {
                setCurrentTitle(title);
            }
        }
    }, [total, completed]);

    useEffect(() => {
        if (isOpen) {
            setIsExpanded(true);
        } else {
            setCurrentTitle(title);
            dispatch(reset());
        }
    }, [isOpen]);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleOpenClick = (value: any) => {
        if (onOpenClick) {
            onOpenClick(value);
        }
    };

    const handleCloseClick = () => {
        close();
    };

    const open = () => {
        setIsOpen(true);
        setOpen && setOpen(true);
    };

    const close = () => {
        setIsOpen(false);
        setOpen && setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    return createPortal(<>
        <Fade in={isOpen}>
            <Card raised sx={{
                mr: 3,
                width: 480,
                position: 'fixed',
                right: 0,
                bottom: 0,
            }}>
                <CardHeader
                    title={<Typography variant="h6">{currentTitle}</Typography>}
                    disableTypography
                    action={
                        <>
                            <IconButton onClick={handleExpandClick}>
                                {isExpanded ? <ExpandMore /> : <ExpandLess />}
                            </IconButton>
                            <IconButton onClick={handleCloseClick}>
                                <Close/>
                            </IconButton>
                        </>
                    }
                />
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{
                        p: 0,
                        maxHeight: 340,
                        overflow: 'auto',
                    }}>
                        {!!items?.length && (
                            <List dense={false}>
                                {items.map((item: any) => {
                                    return (
                                        <ListItem key={item.id} disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <Description />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.data.name}
                                                    secondary={fileSizeFormat(item.data.size)}
                                                />
                                                {item.data.path && <ListItemText
                                                    primary={`(${item.data.path})`}
                                                    sx={{pr: 1, textAlign: "right", minWidth: 'fit-content'}}
                                                />}
                                                {(item.status == 'processing')? <CircularProgress size={28} /> : <></>}
                                                {(item.status == 'success')? <IconButton onClick={() => handleOpenClick(item)}><OpenInNew /></IconButton> : <></>}
                                                {(item.status == 'error')? <ErrorOutline /> : <></>}
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        )}
                    </CardContent>
                </Collapse>
            </Card>
        </Fade>
    </>,
    document.body);
};

export default forwardRef(ProgressCard);