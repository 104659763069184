import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import 'gridstack/dist/gridstack.min.css';
import {
    COUNT_RECORD_QUERY,
    LIST_COST_CODE,
    LIST_DIVISION,
    LIST_PHASE,
    LIST_SCHEDULE_ITEM_STATUS,
    ListItemsQuery,
    ScheduleItemsQuery, SettingsQuery,
    TasksQuery
} from 'smx-components';
import {useLazyLoadQuery} from 'react-relay';
import {useAppSelector} from './utilities/hooks';
import {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

function AppLoader() {
    const { projects} = useAppSelector((state) => state.projects);
    const [fetchKeyUpdateItemsForChangeProjectList, setFetchKeyUpdateItemsForChangeProjectList] = useState<string>('');

    const newOriginSetting = useLazyLoadQuery(SettingsQuery, {}, { fetchPolicy: 'network-only' });
    const newOriginScheduleItems = useLazyLoadQuery(ScheduleItemsQuery, {first: COUNT_RECORD_QUERY}, { fetchPolicy: 'network-only', fetchKey: fetchKeyUpdateItemsForChangeProjectList });
    const newOriginTasks = useLazyLoadQuery(TasksQuery, {}, { fetchPolicy: 'network-only' });
    const newOriginPhase = useLazyLoadQuery(ListItemsQuery, {type: LIST_PHASE}, { fetchPolicy: 'network-only' });
    const newOriginDivision = useLazyLoadQuery(ListItemsQuery, {type: LIST_DIVISION}, { fetchPolicy: 'network-only' });
    const newOriginCostCode = useLazyLoadQuery(ListItemsQuery, {type: LIST_COST_CODE}, { fetchPolicy: 'network-only' });
    const newOriginScheduleItem = useLazyLoadQuery(ListItemsQuery, {type: LIST_SCHEDULE_ITEM_STATUS}, { fetchPolicy: 'network-only' });
    const newOriginTaskItem = useLazyLoadQuery(ListItemsQuery, {type: LIST_SCHEDULE_ITEM_STATUS}, { fetchPolicy: 'network-only' });

    useEffect(() => {
        setFetchKeyUpdateItemsForChangeProjectList(uuidv4());
    }, [projects]);

    return (
        <></>
    );
}

export default AppLoader;

