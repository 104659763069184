import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export default styled(Typography)(({ theme, ownerState }: any) => {
    const { palette, typography, functions } = theme;
    const { color, textTransform, verticalAlign, fontWeight, opacity, textGradient } = ownerState;

    const { gradients, transparent } = palette;
    const { fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold } = typography;

    const fontWeights = {
        light: fontWeightLight,
        regular: fontWeightRegular,
        medium: fontWeightMedium,
        bold: fontWeightBold,
    };

    // styles for the typography with textGradient={true}
    const gradientStyles = () => ({
        backgroundImage:
            functions && functions.linearGradient ?
                color !== 'inherit' && color !== 'text' && color !== 'white' && gradients[color]
                    ? functions.linearGradient(gradients[color].main, gradients[color].state)
                    : functions.linearGradient(gradients.dark.main, gradients.dark.state)
                : 'none',
        display: 'inline-block',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: transparent.main,
        position: 'relative',
        zIndex: 1,
    });

    return {
        opacity,
        textTransform,
        verticalAlign,
        textDecoration: 'none',
        color: color === 'inherit' || !palette[color] ? 'inherit' : palette[color].main,
        fontWeight: fontWeights[fontWeight as keyof typeof fontWeights] && fontWeights[fontWeight as keyof typeof fontWeights],
        ...(textGradient && gradientStyles()),
    };
});
