import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import {
    resetCurrentProject,
    setCurrentProjectById
} from 'modules/projects/slices/projectsSlice';
import { current } from '@reduxjs/toolkit';
import { fetchProject } from 'modules/projects/slices/projectsActions';
import { reset } from 'slices/accountSlice';

const ProtectedRoute = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    const { currentProject, projects } = useAppSelector((state) => state.projects);
    const { projectId } = useParams<{ projectId: string }>();

    useEffect(() => {
        if (!accessToken) {
            navigate('/signin');
        }
    }, [location, accessToken]);

    useEffect(() => {
        if (projectId) {
            if (projects) dispatch(setCurrentProjectById(projectId));
            if (!currentProject) dispatch(fetchProject(projectId));
        } else {
            dispatch(resetCurrentProject());
        }
    }, [dispatch, projectId, currentProject]);

    if (!accessToken) {
        return <></>;
    }

    return <Outlet />;
};

export default ProtectedRoute;
