import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import { User, Users } from 'interfaces/users';
import { useAppDispatch } from 'utilities/hooks';

const UsersList = ({
    users = [],
}: {
    users: Users;
}) => {
    const dispatch = useAppDispatch();

    if (users?.length === 0) {
        return <></>;
    }

    return <List sx={{
        '& .MuiListItem-root .hidden': {
            visibility: 'hidden',
        },
        '& .MuiListItem-root:hover .hidden': {
            visibility: 'visible',
        },
    }}>
        {users.map((user: User, index: number) => {
            return <ListItemButton key={index} disableGutters>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>{user.initials}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<>
                            {user.name}
                        </>}
                    />
                </ListItem>
            </ListItemButton>;
        })}
    </List>;
};

export default UsersList;