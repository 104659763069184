import {
    Backdrop,
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {
    Add,
    Delete,
    Edit,
} from '@mui/icons-material';
import { debounce } from '@mui/material/utils';
import {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import { BaseModalRef } from 'modals/BaseModal';
import {
    fetchProjects,
    deleteProject
} from 'modules/projects/slices/projectsActions';
import { setCurrentProject } from 'modules/projects/slices/projectsSlice';
import ProjectCreateModal from 'modules/projects/components/ProjectCreateModal';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';

const ProjectsPage = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { projects, loading } = useAppSelector((state) => state.projects);
    const projectCreateModal = useRef<BaseModalRef>(null);

    const [items, setItems] = useState<Array<any>>([]);

    useEffect(() => {
        dispatch(fetchProjects(null));
    }, [location, currentTenant]);

    useEffect(() => {
        if (!projects) return;

        setItems(projects);
    }, [projects]);

    const handleCreate = () => {
        dispatch(fetchProjects(null));
    };

    const handleDelete = (row: any) => {
        if (!window.confirm('Are you sure you want to delete this project?')) return;
        dispatch(deleteProject(row.id));
        dispatch(fetchProjects(null));
    };

    const handleChangeSearch = useMemo(() => {
        return debounce((newValue: string) => {
            if (newValue) {
                dispatch(fetchProjects(newValue));
            } else {
                dispatch(fetchProjects(null));
            }
        }, 500);
    }, []);

    return <>
        <Backdrop sx={{ color: '#FFFFFF', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={() => projectCreateModal?.current?.open()}
                            startIcon={<Add />}
                        >
                            Create Project
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={4} sx={{ pt: 2, pb: 3 }}>
                    {projects &&
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChangeSearch(event.target.value);
                            }}
                        />
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {projects && projects.length > 0 &&
                    projects.map((project, index) => {
                        return <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    p: 2,
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 2,
                                    background: 'linear-gradient(135deg, #f3e7e9, #e3eeff)',
                                    transition: 'transform 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                    }
                                }}
                                onClick={() => {
                                    dispatch(setCurrentProject(project));
                                    navigate(`/projects/${project.id}/dashboards`);
                                }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant="h5" sx={{ mt: 1, ml: 1 }} color="common.black">{project.name}</Typography>
                                    <Typography variant="subtitle1" sx={{ ml: 1 }} color="common.black"># {project.number}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', ml: 1 }}>
                                    <Edit style={{ color: 'black', cursor: 'pointer' }} fontSize='small' onClick={() => projectCreateModal?.current?.open(project)} />
                                    <Delete style={{ color: 'black', cursor: 'pointer', marginLeft: '10px' }} fontSize='small' onClick={() => { handleDelete(project) }} />
                                </Box>
                            </Card>
                        </Grid>;
                    })
                }
            </Grid>
        </Box>
        <ProjectCreateModal
            ref={projectCreateModal}
            onSubmit={handleCreate}
        ></ProjectCreateModal>
    </>;
};

export default ProjectsPage;