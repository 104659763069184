import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from '../utilities/axios';
import { RootState } from '../store';
import { setCurrentTenant } from './tenantsSlice';

export const fetchAccount = createAsyncThunk('account', async (_: void, { dispatch, getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;

        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);
        const response = await axios.get('api/v1/account');
        // NOTE: System has been designed to support multiple tenants, but the current implementation only supports one tenant.
        // Therefore, the current tenant is still used in Documents module.
        dispatch(setCurrentTenant(response.data.data.tenant));

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updateAccount = createAsyncThunk('account/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updateEmail = createAsyncThunk('account/email/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account/email', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const resendByEmail = createAsyncThunk('account/email/resend', async (_: void, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/email/resend');

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const verifyEmail = createAsyncThunk('account/email/verify', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/email/verify', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updatePhone = createAsyncThunk('account/phone/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account/phone', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const resendByPhone = createAsyncThunk('account/phone/resend', async (_: void, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/phone/resend');

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const verifyPhone = createAsyncThunk('account/phone/verify', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/phone/verify', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updatePassword = createAsyncThunk('account/password/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account/password', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchPermissions = createAsyncThunk('account/permissions', async (_: void, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;

        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);
        const response = await axios.get('api/v1/account/permissions');

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});