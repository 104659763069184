import {useEffect, useState} from "react";
import {useAppSelector} from '../utilities/hooks';
import {useLocation} from 'react-router-dom';
import {User} from '../interfaces/users';

const useOs = () => {
    const location = useLocation();
    const user = useAppSelector<User | null>((state) => state.account.user);

    const [isShowEmailVerificationAlert, setIsShowEmailVerificationAlert] = useState(false);
    const [isShowPhoneVerificationAlert, setIsShowPhoneVerificationAlert] = useState(false);

    useEffect(() => {
        const byLocation = !location.pathname.includes('/profile');
        if (byLocation) {
            setIsShowEmailVerificationAlert(byLocation);
            setIsShowPhoneVerificationAlert(byLocation);
        }
        if (user) {
            setIsShowEmailVerificationAlert(!user.email_verified_at);
            setIsShowPhoneVerificationAlert(!user.phone_verified_at);
        } else {
            setIsShowEmailVerificationAlert(false);
            setIsShowPhoneVerificationAlert(false);
        }
    }, [location, user]);

    return {isShowEmailVerificationAlert, isShowPhoneVerificationAlert};
};

export default useOs;
