import { Environment, Network, RecordSource, Store } from 'relay-runtime';

const DEFAULT_TOKEN = '';

const createEnvironment = (token : string) => {
    async function fetchQuery(operation: any, variables: any) {
        const response = await fetch(`${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}/api/v1/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                query: operation.text,
                variables,
            }),
        })

        return response.json();
    }

    return new Environment({
        network: Network.create(fetchQuery),
        store: new Store(new RecordSource()),
    });
}

const dynamicEnvironment = {
    token: DEFAULT_TOKEN,
    environment: createEnvironment(DEFAULT_TOKEN),
};

export const getEnvironment = (token: string): Environment => {
    if(token && dynamicEnvironment.token === token){
        return dynamicEnvironment.environment;
    }

    dynamicEnvironment.token = token;
    dynamicEnvironment.environment = createEnvironment(token);

    return dynamicEnvironment.environment;
}
