import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from '../utilities/axios';


export const fetchInvitations = createAsyncThunk('invitations/fetch', async (team: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/invitations/${state.tenants.currentTenant?.id}/list/${team}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchInvitation = createAsyncThunk('invitation/fetch', async (code: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.tenants.currentTenant) return;
        const axios = useAxios(null, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/invitations/${code}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchUsersForInvitation = createAsyncThunk('invitations/check', async (contact: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        if (contact === '') return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/invitations/${state.tenants.currentTenant?.id}/check`, {
            contact: contact,
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const resendInvitation = createAsyncThunk('invitations/resend', async (teamId: string, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/invitations/${state.tenants.currentTenant?.id}/resend/${teamId}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const syncInvitations = createAsyncThunk('invitations/sync', async (data: {teamId: string, invitations: Array<any>}, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.put(`api/v1/invitations/${state.tenants.currentTenant?.id}/sync/${data.teamId}`, data.invitations);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});



