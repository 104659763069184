import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useLocation } from 'react-router-dom';
import { deleteCostCode, fetchCostCodes } from 'modules/settings/slices/costCodesActions';
import { CostCode, CostCodes } from 'modules/settings/interfaces/costCodes';
import { Button, List, ListItem, ListItemIcon } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { BaseModalRef } from 'modals/BaseModal';
import CostCodeModal from '../CostCodeModal/CostCodeModal';
import WTypography from 'containers/WTypography';
import { SitemaxTable } from 'containers/SitemaxTable/SitemaxTable';

export const labelCostCodesTab = 'Cost Codes';

const CostCodesTab = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { costCodes, loading, error } = useAppSelector((state) => state.costCodes);
    const costCodeModal = useRef<BaseModalRef>(null);

    const [items, setItems] = useState<CostCodes>([]);

    useEffect(() => {
        dispatch(fetchCostCodes(null));
    }, [location, currentTenant]);

    useEffect(() => {
        if (!costCodes) return;

        setItems(costCodes);
    }, [costCodes]);

    const handleCreate = () => {
        dispatch(fetchCostCodes(null));
    };

    const handleDelete = (row: any) => {
        dispatch(deleteCostCode(row.id));
        dispatch(fetchCostCodes(null));
    };

    const headers = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params: { row?: CostCode }) => {
                return <><WTypography sx={{ pl: 2 }}>{params.row?.name}</WTypography></>;
            },
        },
        {
            field: 'code',
            headerName: 'Code',
            flex: 1,
            renderCell: (params: { row?: CostCode }) => {
                return <><WTypography sx={{ pl: 2 }}>{params.row?.code}</WTypography></>;
            },
        },
        {
            actions: [
                {
                    title: 'Edit',
                    onClick: (row: any) => {
                        costCodeModal?.current?.open(row);
                    },
                    icon: <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>,
                },
                {
                    title: 'Delete',
                    onClick: (row: any) => {
                        handleDelete(row);
                    },
                    icon: <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>,
                },
            ],
        },
    ];

    return <>
        <Button
            variant="contained"
            disableElevation
            onClick={() => costCodeModal?.current?.open()}
            startIcon={<Add />}
            sx={{ marginBottom: 2 }}
        >
            Create Cost Code
        </Button>
        <SitemaxTable
            headers={headers}
            rows={costCodes}
            getData={() => {
                dispatch(fetchCostCodes(null));
            }}
            loading={loading}
        ></SitemaxTable>
        <CostCodeModal
            ref={costCodeModal}
            onSubmit={handleCreate}
        ></CostCodeModal>
    </>;
};

export default forwardRef(CostCodesTab);
