import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Skeleton,
    TextField
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { Classification } from 'modules/settings/interfaces/classifications';
import { createClassification, updateClassification } from 'modules/settings/slices/classificationsActions';

export interface ClassificationModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const ClassificationModal = (
    props: ClassificationModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.classifications);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [classification, setClassification] = useState<Classification | undefined>(undefined);
    const formId = 'classification_create_form_' + nanoid();

    useEffect(() => {
        if (isOpen) {
            reset(classification);
        }
    }, [isOpen]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const onSubmit = (data: any) => {
        if (classification && classification.id) {
            data.id = classification.id;
            dispatch(updateClassification(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        } else {
            dispatch(createClassification(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        }
    };

    const open = (classification: Classification | undefined = undefined) => {
        if (classification) {
            setClassification(classification);
        } else {
            setClassification({
                name: '',
            });
        }
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: (classification?: Classification | undefined) => open(classification),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={'Create classification'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        { classification && classification.id ? 'Update' : 'Create' }
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        id="name"
                        variant="outlined"
                        label="Name"
                        {...register("name")}
                        error={!!error?.errors?.name}
                        helperText={error?.errors?.name?.[0]}
                        autoFocus
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(ClassificationModal);
