import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Phase, Phases } from '../interfaces/phases';
import {
    createPhase,
    fetchPhases,
    updatePhase
} from './phasesActions';

interface phasesState {
    loading: boolean;
    error: any | null;
    phases: Phases;
};

export const initialState: phasesState = {
    loading: false,
    error: null,
    phases: [],
};

const phasesSlice = createSlice({
    name: 'phases',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchPhases.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchPhases.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.phases = action.payload.data;
        })
        builder.addCase(fetchPhases.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.phases = [];

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(createPhase.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createPhase.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(createPhase.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(updatePhase.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updatePhase.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(updatePhase.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
} = phasesSlice.actions;
export default phasesSlice.reducer;
