import {
    Add,
    Close,
    ContentCopy,
    DeleteSweep,
    Description,
    DriveFileMove,
    DriveFileRenameOutline,
    ExpandMore,
    Folder,
    Refresh,
    RestorePage,
    Share,
    SnippetFolder,
    Upload
} from '@mui/icons-material';
import {
    Avatar,
    AvatarGroup,
    Button,
    Chip,
    Fade,
    Grid,
    ListItemIcon,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import {
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    MuiEvent,
} from '@mui/x-data-grid';
import ProgressCard, { ProgressCardRef } from 'components/ProgressCard';
import UploadMenu, { UploadMenuRef } from 'components/UploadMenu';
import UserAllowed from 'components/UserAllowed';
import WTypography from 'containers/WTypography';
import {
    Category,
    Document,
    Documents,
} from 'interfaces/documents';
import {
    EntityContext,
} from 'interfaces/teams';
import { BaseModalRef } from 'modals/BaseModal';
import CategoriesTreeModal from 'modals/CategoriesTreeModal';
import CategoryCreateModal from 'modals/CategoryCreateModal';
import CategoryRenameModal from 'modals/CategoryRenameModal';
import CategoryShareModal from 'modals/CategoryShareModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import DocumentRenameModal from 'modals/DocumentRenameModal';
import DocumentShareModal from 'modals/DocumentShareModal';
import DocumentVersionsModal from 'modals/DocumentVersionsModal';
import FilterModal from 'modals/FilterModal';
import GuestTeamCreateModal from 'modals/GuestTeamCreateModal';
import GuestTeamMembersUpdateModal from 'modals/GuestTeamMembersUpdateModal';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import Moment from 'react-moment';
import {
    moveCategory,
    softDeleteCategory
} from 'slices/categoriesActions';
import {
    setExcluded as setExcludedCategories
} from 'slices/categoriesSlice';
import {
    setCategory
} from 'slices/categorySlice';
import {
    setDocument
} from 'slices/documentSlice';
import {
    copyDocument,
    fetchDocuments,
    fetchSharedDocuments,
    moveDocument,
    softDeleteDocument
} from 'slices/documentsActions';
import {
    deleteFilter,
    setParams
} from 'slices/documentsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';
import { SitemaxTable, SitemaxTableHeader, SitemaxTableActionItem } from '../../../containers/SitemaxTable/SitemaxTable';

const DocumentsTable = ({
    categoryId = null,
    list = [],
    goTo,
    fetchData,
    shared = false,
}: {
    hasProjects?: boolean,
    categoryId?: number | null,
    list?: Documents,
    goTo: (categoryId?: number) => void,
    fetchData: () => void,
    shared?: boolean,
}) => {
    const dispatch = useAppDispatch();

    const { currentTenant } = useAppSelector((state) => state.tenants);
    const {
        users,
        teams,
        loading,
        params,
    } = useAppSelector((state) => state.documents);

    const documentMarkupModal = useRef<BaseModalRef>(null);
    const documentRenameModal = useRef<BaseModalRef>(null);
    const documentShareModal = useRef<BaseModalRef>(null);
    const documentVersionsModal = useRef<BaseModalRef>(null);
    const categoryCreateModal = useRef<BaseModalRef>(null);
    const categoryRenameModal = useRef<BaseModalRef>(null);
    const categoryShareModal = useRef<BaseModalRef>(null);
    const confirmBulkDeleteModal = useRef<BaseModalRef>(null);
    const confirmRowDeleteModal = useRef<BaseModalRef>(null);
    const copyBulkModal = useRef<BaseModalRef>(null);
    const copyRowModal = useRef<BaseModalRef>(null);
    const moveBulkModal = useRef<BaseModalRef>(null);
    const moveRowModal = useRef<BaseModalRef>(null);
    const progressCard = useRef<ProgressCardRef>(null);
    const guestTeamCreateModal = useRef<BaseModalRef>(null);
    const guestTeamMembersUpdateModal = useRef<BaseModalRef>(null);
    const filterModal = useRef<BaseModalRef>(null);
    const uploadMenuRef = useRef<UploadMenuRef>(null);

    const [uploadMenuAnchor, setUploadMenuAnchor] = useState<null | HTMLElement>(null);
    const [currentItem, setCurrentItem] = useState<null | any>(null);
    const [currentEntity, setCurrentEntity] = useState<null | any>(null);
    const [selectedItems, setSelectedItems] = useState<Array<any>>([]);

    const fetch = shared ? fetchSharedDocuments : fetchDocuments;

    const handleCategory = (category: Category) => {
        goTo(category.id);
    };

    /**
     * Upload
     */
    const handleUploadMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setUploadMenuAnchor(event.currentTarget);
    };

    const handleUploadMenuClose = () => {
        setUploadMenuAnchor(null);
    };

    const handleUploadMenuComplete = (values: any[]) => {
        dispatch(fetch(categoryId));
    };

    const onDrop = useCallback((acceptedFiles: any) => {
        uploadMenuRef.current?.drop(acceptedFiles);
    }, []);

    const { getRootProps } = useDropzone({ onDrop });

    const openGuestTeamModal = (item: any) => {
        let team: any = null;
        if (item.type === 'category') {
            team = teams[item?.uuid];
        } else {
            team = teams[item?.id];
        }

        setCurrentEntity(item);

        if (team) {
            guestTeamMembersUpdateModal.current?.open();
        } else {
            guestTeamCreateModal.current?.open();
        }
    };

    /**
     * Markup
     */
    const handleDocumentOpen = (document: Document) => {
        dispatch(setDocument(document));
        documentMarkupModal.current?.open();
    };

    const openVersions = (item: any) => {
        dispatch(setDocument(item));
        documentVersionsModal.current?.open();
    };

    /**
     * Move
     */
    const openBulkMoveModal = (selected: Array<any>) => {
        let categories = selected.filter((item: any) => item.type === 'category');
        // exclude categories which we will be moving from the category tree modal
        setSelectedItems(selected);
        dispatch(setExcludedCategories(categories));
        moveBulkModal.current?.open();
    };

    const bulkMove = (destination: Category | null) => {
        let promises: any[] = [];
        selectedItems.forEach((item: any) => {
            if (item.type === 'category') {
                promises.push(dispatch(moveCategory({
                    id: item!.id,
                    parent_id: destination?.id || null,
                })));
            } else {
                promises.push(dispatch(moveDocument({
                    id: item!.id,
                    category_id: destination?.id || null,
                })));
            }
        });

        Promise.all(promises).then((responses) => {
            dispatch(fetch(categoryId));
        }).catch(() => { });
    };

    const openMoveModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);
        if (item.type === 'category') {
            dispatch(setExcludedCategories([item]));
        }

        moveRowModal.current?.open();
    };

    const moveItem = (destination: Category | null) => {
        let promises: any[] = [];

        if (currentItem.type === 'category') {
            promises.push(dispatch(moveCategory({
                id: currentItem.id,
                parent_id: destination?.id || null,
            })));
        } else {
            promises.push(dispatch(moveDocument({
                id: currentItem.id,
                category_id: destination?.id || null,
            })));
        }

        Promise.all(promises).then((responses) => {
            dispatch(fetch(categoryId));
        }).catch(() => { });
        setCurrentItem(null);
    };

    /**
     * Copy
     */
    const bulkCopy = (destination: Category | null) => {
        let promises: any[] = [];

        selectedItems.map((document: Document) => {
            promises.push(dispatch(copyDocument({
                id: document.id,
                category_id: destination?.id || null,
            })));
        });

        Promise.all(promises).then((responses) => {
            dispatch(fetch(categoryId));
        }).catch(() => { });
    };

    const openCopyRowModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);

        copyRowModal.current?.open();
    };

    const copyItem = (destination: Category | null) => {
        let promises: any[] = [];

        promises.push(dispatch(copyDocument({
            id: currentItem.id,
            category_id: destination?.id || null,
        })));

        Promise.all(promises).then((responses) => {
            dispatch(fetch(categoryId));
        }).catch(() => { });
        setCurrentItem(null);
    };

    /**
     * Delete
     */
    const bulkDelete = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        let promises: any[] = [];
        selectedItems.forEach((item: any) => {
            if (item.type === 'category') {
                promises.push(dispatch(softDeleteCategory(item.id)));
            } else {
                promises.push(dispatch(softDeleteDocument(item.id)));
            }
        });
        Promise.all(promises).then((responses) => {
            dispatch(fetch(categoryId));
        }).catch(() => { });
    };

    const openDeleteItemModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);
        confirmRowDeleteModal.current?.open();
    };

    const deleteItem = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        let promises: any[] = [];
        if (currentItem.type === 'category') {
            promises.push(dispatch(softDeleteCategory(currentItem.id)));
        } else {
            promises.push(dispatch(softDeleteDocument(currentItem.id)));
        }

        Promise.all(promises).then((responses) => {
            dispatch(fetch(categoryId));

        }).catch(() => { });
        setCurrentItem(null);
    };

    /**
     * Rename
     */
    const rename = (item: any | Array<any>) => {
        if (!item) return;
        if (Array.isArray(item)) {
            if (item.length === 0) return;
            item = item[0];
        }
        setCurrentItem(item);
        if ((item.type === 'category')) {
            openCategoryRenameModal(item);
        } else {
            openDocumentRenameModal(item);
        }
    };

    const openCategoryRenameModal = (item: Category) => {
        dispatch(setCategory(item));
        categoryRenameModal.current?.open();
    };

    const openDocumentRenameModal = (item: Document) => {
        dispatch(setDocument(item));
        documentRenameModal.current?.open();
    };

    /**
     * Team
     */
    const handleGuestTeamCreate = (item: any | Array<any>) => {
        if (Array.isArray(item)) {
            if (item.length != 1) return;
            item = item[0];
        }
        setCurrentEntity(item);
        let id = item.type == 'category' ? item?.uuid : item?.id;
        let team: any = teams[id];

        if (team) {
            guestTeamMembersUpdateModal?.current?.open();
        } else {
            guestTeamCreateModal?.current?.open();
        }
    };

    /**
     * Filters
     */
    const applyFilters = () => {
        dispatch(fetch(categoryId));
    };

    const renderFilter = (key: string, object: any) => {
        if (object.value === null || object.value === undefined) return;

        if (object.value instanceof Date || typeof object.value == 'string') {
            let date = object.value;
            if (typeof object.value === 'string') date = new Date(object.value);
            return <Chip
                label={
                    <>
                        <Typography fontWeight="bold" variant="caption">
                            {object.label}:
                        </Typography>{' '}
                        {date.toISOString()?.slice(0, 10)}
                    </>
                }
                key={key}
                sx={{ mr: 2, mb: 1 }}
                icon={
                    <Avatar onClick={() => handleDeleteFilter(key)} sx={{ width: 10, height: 10 }}>
                        <Close />
                    </Avatar>
                }
            />
        } else {
            return object.value.map(function (el: any) {
                if (el.name || el.label) {
                    return <Chip
                        label={
                            <>
                                <Typography fontWeight="bold" variant="caption">
                                    {object.label}:
                                </Typography>{' '}
                                {(el.name || el.label)}
                            </>
                        }
                        key={key + (el.name || el.label)}
                        sx={{ mr: 2, mb: 1 }}
                        icon={
                            <Avatar onClick={() => handleDeleteFilter(key, el.id)} sx={{ width: 10, height: 10 }}>
                                <Close />
                            </Avatar>
                        }
                    />
                }
            });
        }
    };

    const handleDeleteFilter = (key: string, id: any = null) => {
        dispatch(deleteFilter({ key, id }));
        dispatch(fetch(categoryId));
    };

    if (!currentTenant) {
        return <></>;
    }

    var columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => {
                if (params.row.type === 'category') {
                    return <>
                        {
                            params.row.object_model == 'Project' ?
                                <SnippetFolder />
                                :
                                <Folder />
                        }
                        <WTypography sx={{ pl: 2 }}>{params.row.name}</WTypography>
                    </>
                } else {
                    return <><Description /><WTypography sx={{ pl: 2 }}>{params.row.name}</WTypography>
                        {params.row.version > 1 && <Chip label={`v${params.row.version}`} size="small" sx={{ ml: 1 }} />}
                    </>
                }
            }
        },
        {
            field: 'team',
            headerName: 'Have Access',
            sortable: false,
            resizable: false,
            width: 180,
            renderCell: (params) => {
                if (!teams) return <></>;

                let _team = null;
                if (params.row.type === 'category') {
                    _team = teams[params.row.uuid];
                } else {
                    _team = teams[params.row.id];
                }
                if (!_team) return <></>;
                if (!_team.members.length) return <></>;

                return <AvatarGroup max={3}>
                    {_team.members.map((_member: any) => {
                        return <Tooltip
                            key={`m${_member.id}`}
                            title={_member.full_name}
                            placement="top"
                        >
                            <Avatar
                                alt={_member.full_name}
                                sx={{ width: 32, height: 32, fontSize: 16 }}
                            >
                                {_member.initials}
                            </Avatar>
                        </Tooltip>;
                    })}
                </AvatarGroup>;
            }
        },
        {
            field: 'size_formatted',
            headerName: 'Size',
            width: 100
        },
        {
            field: 'updated_at',
            headerName: 'Last Modified',
            renderCell: (params) => <Moment date={params.row.updated_at} fromNow />
        },
        {
            field: 'updated_by',
            headerName: 'Modified By',
            sortable: false,
            resizable: false,
            align: 'center',
            renderCell: (params) => {
                let _user = users[params.row.updated_by];
                if (!_user) return <></>;

                return <Tooltip
                    key={`u${_user.id}`}
                    title={_user.full_name}
                    placement="top"
                >
                    <Avatar
                        alt={_user.full_name}
                        sx={{ width: 32, height: 32, fontSize: 16 }}
                    >
                        {_user.initials}
                    </Avatar>
                </Tooltip>;
            },
        },
    ];

    if (params?.filters || params?.query) {
        columns.splice(2, 0, {
            field: 'folder',
            headerName: 'Folder',
            flex: 1,
            renderCell: (params) => {
                if (params.row.type === 'document' && params.row.category_id !== categoryId) {
                    return <><Typography>{params.row.category_name}</Typography></>
                }
            }
        });
    }

    const headers = (columns as Array<SitemaxTableHeader>).concat(!shared ? [
        {
            actions: [
                // {
                //     title: 'Open',
                //     onClick: (row: any) => {
                //         handleRowOpen(row);
                //     },
                //     icon: <ListItemIcon>
                //         <OpenWith fontSize="small" />
                //     </ListItemIcon>,
                //     hidden: function (row: any) {
                //         return row.type == 'category';
                //     },
                // },
                {
                    title: 'Manage Sharing',
                    onClick: (row: any) => {
                        openGuestTeamModal(row);
                    },
                    icon: <ListItemIcon>
                        <Share fontSize="small" />
                    </ListItemIcon>,
                    hidden: function (row: any) {
                        return row.type == 'category' && row.object_model == 'Project';
                    },
                },
                {
                    title: 'Versions',
                    onClick: (row: any) => {
                        openVersions(row);
                    },
                    icon: <ListItemIcon>
                        <RestorePage fontSize="small" />
                    </ListItemIcon>,
                    hidden: function (row: any) {
                        return row.type == 'category';
                    },
                },
                {
                    title: 'Rename',
                    onClick: (selected: Array<any>) => {
                        rename(selected);
                    },
                    icon: <ListItemIcon>
                        <DriveFileRenameOutline fontSize="small" />
                    </ListItemIcon>,
                    hidden: function (row: any) {
                        return row.object_model == 'Project';
                    },
                },
                {
                    title: 'Copy',
                    onClick: (row: any) => {
                        openCopyRowModal(row);
                    },
                    icon: <ListItemIcon>
                        <ContentCopy fontSize="small" />
                    </ListItemIcon>,
                    hidden: function (row: any) {
                        return row.type == 'category' || row.object_model == 'Project';
                    },
                },
                {
                    title: 'Move',
                    onClick: (row: any) => {
                        openMoveModal(row);
                    },
                    icon: <ListItemIcon>
                        <DriveFileMove fontSize="small" />
                    </ListItemIcon>,
                    hidden: function (row: any) {
                        return row.object_model == 'Project';
                    },
                },
                {
                    title: 'Delete',
                    onClick: (row: any) => {
                        openDeleteItemModal(row);
                    },
                    icon: <ListItemIcon>
                        <DeleteSweep fontSize="small" />
                    </ListItemIcon>,
                    hidden: function (row: any) {
                        return row.object_model == 'Project';
                    },
                }
            ]
        }
    ] : []);

    const actions: Array<SitemaxTableActionItem> = [
        {
            title: 'Delete',
            selectionRequired: true,
            onClick: (selected: Array<any>) => {
                setSelectedItems(selected);
                confirmBulkDeleteModal?.current?.open();
            },
            icon: <DeleteSweep fontSize="small" />,
        },
        // {
        //     title: 'Rename',
        //     selectionRequired: true,
        //     onClick: (selected: Array<any>) => {
        //         rename(selected);
        //     },
        //     icon: <DeleteSweep fontSize="small" />,
        //     disabled: (selected: Array<any>) => {
        //         return selected.length != 1;
        //     },
        // },
        {
            title: 'Copy',
            selectionRequired: true,
            onClick: (selected: Array<any>) => {
                setSelectedItems(selected);
                copyBulkModal.current?.open();
            },
            icon: <DeleteSweep fontSize="small" />,
            disabled: (selected: Array<any>) => {
                return selected.filter((item) => item.type == 'category').length != 0;
            },
        },
        {
            title: 'Move',
            selectionRequired: true,
            onClick: openBulkMoveModal,
            icon: <DriveFileMove fontSize="small" />,
        },
        // {
        //     title: 'Manage Sharing',
        //     selectionRequired: true,
        //     onClick: handleGuestTeamCreate,
        //     icon: <DriveFileMove fontSize="small" />,
        //     disabled: (selected: Array<any>) => {
        //         return selected.length != 1;
        //     },
        // },
        // {
        //     title: 'Versions',
        //     selectionRequired: true,
        //     onClick: (selected: Array<any>) => {
        //         openVersions(selected[0]);
        //     },
        //     icon: <DriveFileMove fontSize="small" />,
        //     disabled: (selected: Array<any>) => {
        //         return selected.length != 1 || selected[0].type == 'category';
        //     },
        // },
    ];

    return <>
        <div {...getRootProps({
            onClick: event => event.stopPropagation()
        })}>
            <Grid container sx={{
                // position: 'sticky',
                // top: -1,
                // zIndex: 'appBar',
                m: '0',
                bgcolor: 'white',
            }}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        { !shared ?
                            <>
                                <UserAllowed permissions={['files.create', 'files.manage', 'files.project.create', 'files.project.manage']}>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        onClick={handleUploadMenuClick}
                                        startIcon={<Upload />}
                                        endIcon={<ExpandMore />}
                                    >
                                        Upload
                                    </Button>
                                </UserAllowed>
                                <UserAllowed permissions={['files.create', 'files.manage', 'files.project.create', 'files.project.manage']}>
                                    <Button
                                        color="inherit"
                                        disableElevation
                                        onClick={() => categoryCreateModal?.current?.open()}
                                        startIcon={<Add />}
                                    >
                                        Create Folder
                                    </Button>
                                </UserAllowed>
                            </>
                            :
                            <></>
                        }
                        <Button
                            color="inherit"
                            disableElevation
                            onClick={fetchData}
                            startIcon={<Refresh />}
                        >
                            Refresh
                        </Button>
                    </Stack>
                    {/* TODO: need to move this into the SitemaxTable somehow */}
                    <Fade in={!!params.filters && !!Object.keys(params.filters).length} hidden={!params.filters || !Object.keys(params.filters).length} appear={false}>
                        <Stack direction="row" alignItems="center" sx={{ pt: 1 }} className="d-flex flex-wrap">
                            {Object.keys(params.filters || {}).map((key: string) => (
                                renderFilter(key, params.filters?.[key])
                            ))}
                        </Stack>
                    </Fade>
                </Grid>
            </Grid>
            <SitemaxTable
                headers={headers}
                rows={list}
                getData={(params) => {
                    dispatch(setParams({ query: params?.search || '' }));

                    dispatch(fetch(categoryId));
                }}
                onCellClick={(params: GridCellParams, event: MuiEvent, details: GridCallbackDetails) => {
                    if (params.field != 'name') return;
                    if (params.row.type === 'category') {
                        handleCategory(params.row);
                    } else {
                        handleDocumentOpen(params.row);
                    }
                }}
                loading={loading}
                canSearch={!shared}
                filtersModal={<FilterModal />}
                checkboxSelection={!shared}
                actions={!shared ? actions : []}
                searchEnabled={true}
                hideFooter={false}
            ></SitemaxTable>
        </div>

        <DocumentMarkupModal
            ref={documentMarkupModal}
        ></DocumentMarkupModal>
        <DocumentVersionsModal
            ref={documentVersionsModal}
            onClose={fetchData}
        ></DocumentVersionsModal>
        <DocumentShareModal
            ref={documentShareModal}
            onClose={fetchData}
        ></DocumentShareModal>
        <CategoryShareModal
            ref={categoryShareModal}
            onClose={fetchData}
        ></CategoryShareModal>

        <EntityContext.Provider value={currentEntity}>
            <GuestTeamCreateModal
                ref={guestTeamCreateModal}
                onClose={fetchData}
            ></GuestTeamCreateModal>
            <GuestTeamMembersUpdateModal
                ref={guestTeamMembersUpdateModal}
                onClose={fetchData}
            ></GuestTeamMembersUpdateModal>
        </EntityContext.Provider>

        <CategoryCreateModal
            ref={categoryCreateModal}
            parent={categoryId!}
            onSubmit={fetchData}
        ></CategoryCreateModal>
        <CategoryRenameModal
            ref={categoryRenameModal}
            onSubmit={fetchData}
        ></CategoryRenameModal>
        <DocumentRenameModal
            ref={documentRenameModal}
            onSubmit={fetchData}
        ></DocumentRenameModal>

        <CategoriesTreeModal
            ref={moveBulkModal}
            title={`Move ${selectedItems.length} item(s) to...`}
            submitLabel='Move'
            onSubmit={bulkMove}
        ></CategoriesTreeModal>
        <CategoriesTreeModal
            ref={moveRowModal}
            title={`Move ${currentItem?.type == 'category' ? 'folder' : 'document'} to...`}
            submitLabel='Move'
            onSubmit={moveItem}
        ></CategoriesTreeModal>

        <CategoriesTreeModal
            ref={copyBulkModal}
            title={`Copy ${selectedItems.length} item(s) to...`}
            submitLabel='Copy'
            onSubmit={bulkCopy}
        ></CategoriesTreeModal>
        <CategoriesTreeModal
            ref={copyRowModal}
            title={`Copy ${currentItem?.type == 'category' ? 'folder' : 'document'} to...`}
            submitLabel='Copy'
            onSubmit={copyItem}
        ></CategoriesTreeModal>

        <ConfirmationModal
            ref={confirmBulkDeleteModal}
            text='Are you sure want to delete selected items?'
            onConfirm={bulkDelete}
        ></ConfirmationModal>
        <ConfirmationModal
            ref={confirmRowDeleteModal}
            text='Are you sure want to delete item?'
            onConfirm={deleteItem}
        ></ConfirmationModal>
        <FilterModal
            ref={filterModal}
            onSubmit={applyFilters}
        ></FilterModal>

        <UploadMenu
            uploadref={uploadMenuRef}
            anchorEl={uploadMenuAnchor}
            open={Boolean(uploadMenuAnchor)}
            onClose={handleUploadMenuClose}
            onComplete={handleUploadMenuComplete}
        ></UploadMenu>
        <ProgressCard
            ref={progressCard}
            title='Uploading files...'
            onOpenClick={(item: any) => item.data && handleDocumentOpen(item.data as Document)}
        ></ProgressCard>
    </>;
};

export default DocumentsTable;
