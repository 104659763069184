import React from 'react';
import {Button} from 'antd';
import {COLOR_ITEM, FORMAT_DATE, ItemTaskType} from 'smx-components';
import {setterSchedule} from '../../../../slices/scheduleSlice';
import {useAppDispatch} from '../../../../utilities/hooks';
import {DateTime} from 'luxon';

const ButtonAddTask = () => {
    const dispatch = useAppDispatch();

    const onCLickAdd = () => {
        console.log('click Add Button');
        const newCurrentDate = DateTime.now();
        const newTask = {
            color: COLOR_ITEM,
            datetime: newCurrentDate.toFormat(FORMAT_DATE),
            end_datetime: '09:00:00',
            name: 'new task',
            start_datetime: '08:00:00'
        } as ItemTaskType

        dispatch(setterSchedule({triggerForNewTask: newTask}));

    };

    return <Button type={'default'} onClick={onCLickAdd}>Add Task</Button>;
};

export default ButtonAddTask;
