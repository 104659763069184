import { forwardRef } from 'react';

import WBoxRoot from 'containers/WBox/WBoxRoot';

const WBox = forwardRef(
    ({ variant = 'contained', bgColor = 'transparent', color = 'dark', opacity = 1, borderRadius = 'none', shadow = 'none', ...rest }: any, ref) => (
        <WBoxRoot
            {...rest}
            ref={ref}
            ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
        />
    )
);

export default WBox;