import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Users } from 'interfaces/users';
import { fetchUsersForInvitation } from './invitationsActions';
import {
    fetchGuests,
    fetchUsers,
    fetchUsersAll,
} from './usersActions';

interface UsersState {
    users: Users | null;
    loading: boolean;
    error: any | null;
};

export const initialState: UsersState = {
    users: null,
    loading: false,
    error: null
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.users = action.payload.data;
        })
        builder.addCase(fetchUsers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(fetchGuests.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchGuests.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.users = action.payload.data;
        })
        builder.addCase(fetchGuests.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(fetchUsersAll.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchUsersAll.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.users = action.payload.data;
        })
        builder.addCase(fetchUsersAll.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(fetchUsersForInvitation.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchUsersForInvitation.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.users = action.payload.data;
        })
        builder.addCase(fetchUsersForInvitation.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    }
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;