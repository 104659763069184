import {
    AppBar,
    Avatar,
    Box,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material';
import TenantsList from 'components/TenantsList';
import { NavigationItem } from 'interfaces/navigation';
import { Tenant } from 'interfaces/tenants';
import {ReactHTMLElement, RefObject, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { fetchAccount } from 'slices/accountActions';
import { logout } from 'slices/authActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

import {
    navbar,
} from 'components/DefaultAppBar/styles';

import { setTransparentNavbar, useSitemaxUIController } from 'context';
import { TenantsState } from 'slices/tenantsSlice';

interface IDefaultAppBar{
    absolute?: boolean,
    light?: boolean,
    parentRef: RefObject<HTMLDivElement>
}

const DefaultAppBar: React.FC<IDefaultAppBar> = ({ absolute = false, light = false, parentRef}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [controller, dispatchUI] = useSitemaxUIController();

    const { loading: loadingAccount, user } = useAppSelector((state) => state.account);
    const { settingsMenu } = useAppSelector((state) => state.navigationItems);
    const { currentTenant } = useAppSelector<TenantsState>((state) => state.tenants);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [navbarType, setNavbarType] = useState<any>();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const { transparentNavbar, fixedNavbar } = controller;

    useEffect(() => {
        if (fixedNavbar) {
            setNavbarType('sticky');
        } else {
            setNavbarType('static');
        }

        // NOTE: A function that sets the transparent state of the navbar.
        const handleTransparentNavbar = () => {
            setTransparentNavbar(dispatchUI, (fixedNavbar && parentRef.current?.scrollTop === 0) || !fixedNavbar);
        };
        // The event listener that's calling the handleTransparentNavbar function when scrolling the window.
        parentRef.current?.addEventListener('scroll', handleTransparentNavbar);
        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();
        // Remove event listener on cleanup
        return () => parentRef.current?.removeEventListener('scroll', handleTransparentNavbar);
    }, [dispatchUI, fixedNavbar]);

    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    const handleSingOut = async () => {
        dispatch(logout())
            .unwrap()
            .then(() => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSettings = (event: React.MouseEvent<HTMLElement>, item: NavigationItem) => {
        setAnchorElUser(null);
        navigate(item.url);
    };

    return <>
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme: any) => navbar(theme, { transparentNavbar, absolute, light })}
        >
            <Toolbar>
                <div>
                    {currentTenant === null ? 'SiteMax System' : currentTenant.title}
                </div>
                <Box sx={{ flexGrow: 0 }}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar>{user?.initials}</Avatar>
                    </IconButton>
                    <Menu
                        sx={{ mt: 5 }}
                        elevation={1}
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <Box sx={{ width: 240 }}>
                            {settingsMenu.map((item) => (
                                <MenuItem key={item.url} onClick={(e) => handleSettings(e, item)}>
                                    <Typography textAlign="center">{item.title}</Typography>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem key={'singout'} onClick={handleSingOut}>
                                <Typography textAlign="center">{'Sign Out'}</Typography>
                            </MenuItem>
                        </Box>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
        <Drawer
            anchor="left"
            elevation={0}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
        >
            <Box sx={{ width: 250 }}>
                <Box sx={{ px: 2, pt: 2, pb: 1 }}>
                    <Typography variant="overline">Workspaces</Typography>
                </Box>
                <TenantsList onClick={(tenant: Tenant) => setIsDrawerOpen(false)} />
            </Box>
        </Drawer>
    </>;
}

export default DefaultAppBar;