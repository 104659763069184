import {
    useState
} from 'react';
import CostCodesTab, { labelCostCodesTab } from 'modules/settings/components/CostCodesTab/CostCodesTab';
import DivisionsTab, { labelDivisionsTab } from 'modules/settings/components/DivisionsTab/DivisionsTab';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import ClassificationsTab, { labelClassificationsTab } from 'modules/settings/components/ClassificationsTab/ClassificationsTab';
import PhasesTab, { labelPhasesTab } from 'modules/settings/components/PhasesTab/PhasesTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const SettingsPage = () => {
    const [value, setValue] = useState(0);

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const data = [
        {
            label: labelCostCodesTab,
            content: <CostCodesTab />
        },
        {
            label: labelDivisionsTab,
            content: <DivisionsTab />
        },
        {
            label: labelClassificationsTab,
            content: <ClassificationsTab />
        },
        {
            label: labelPhasesTab,
            content: <PhasesTab />
        }
    ];

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper' }}
        >
            <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {data.map((item, index) => (
                    <Tab key={index} label={item.label}/>
                ))}
            </Tabs>
            {data.map((item, index) => (
                <TabPanel key={index} value={value} index={index}>
                    {item.content}
                </TabPanel>
            ))}
        </Box>
    )
}

export default SettingsPage;