import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Division, Divisions } from '../interfaces/divisions';
import {
    createDivision,
    fetchDivisions,
    updateDivision
} from './divisionsActions';

interface divisionsState {
    loading: boolean;
    error: any | null;
    divisions: Divisions;
};

export const initialState: divisionsState = {
    loading: false,
    error: null,
    divisions: [],
};

const divisionsSlice = createSlice({
    name: 'divisions',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchDivisions.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDivisions.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.divisions = action.payload.data;
        })
        builder.addCase(fetchDivisions.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.divisions = [];

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(createDivision.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createDivision.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(createDivision.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(updateDivision.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateDivision.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(updateDivision.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
} = divisionsSlice.actions;
export default divisionsSlice.reducer;
