import {
    Box,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'utilities/hooks';
import { useEffect } from 'react';

const LegacyAccessPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        if (token) {
            localStorage.setItem('accessToken', token);
            navigate('/');
            window.location.reload();
        } else {
            localStorage.removeItem('accessToken');
            navigate('/signin');
            window.location.reload();
        }
    }, []);

    return <>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            Loading...
        </Box>
    </>;
};

export default LegacyAccessPage;

// http://foo.sitemax.local:3000/access?redirect=1&token=498a8af7cbd7c88a769135095ddcb42a5e9698132eca96d0edd750257e17504d