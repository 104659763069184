import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

import WBox from 'containers/WBox';
import WTypography from 'containers/WTypography';
import React from 'react';

interface TitleProp {
    fontWeight: 'light' | 'medium' | 'bold';
    text: string;
}

interface PercentageProp {
    color: 'success' | 'danger' | 'warning' | 'info';
    text: string;
}

interface IconProp {
    component: string;
    color?: string;
}

interface MiniStatisticsCardProps {
    bgColor?: string;
    title?: TitleProp;
    direction?: 'left' | 'right' | 'up' | 'down';
    percentage?: PercentageProp;
    count: string | null;
    icon: IconProp;
}

const MiniStatisticsCard: React.FC<MiniStatisticsCardProps> = ({
    bgColor = 'white',
    title = { fontWeight: 'medium', text: '' },
    direction = 'right',
    percentage = { color: 'success', text: '' },
    count,
    icon
}) => {
    return (
        <Card>
            <WBox bgColor={bgColor} variant="gradient">
                <WBox p={2}>
                    <Grid container alignItems="center">
                        {direction === "left" ? (
                            <Grid item>
                                <WBox
                                    variant="gradient"
                                    bgColor={bgColor === "white" ? icon.color : "white"}
                                    color={bgColor === "white" ? "white" : "dark"}
                                    width="3rem"
                                    height="3rem"
                                    borderRadius="md"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    shadow="md"
                                >
                                    <Icon fontSize="small" color="inherit">
                                        {icon.component}
                                    </Icon>
                                </WBox>
                            </Grid>
                        ) : null}
                        <Grid item xs={8}>
                            <WBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                                <WTypography
                                    variant="button"
                                    color={bgColor === "white" ? "text" : "white"}
                                    opacity={bgColor === "white" ? 1 : 0.7}
                                    textTransform="capitalize"
                                    fontWeight={title.fontWeight}
                                >
                                    {title.text}
                                </WTypography>
                                <WTypography
                                    variant="h5"
                                    fontWeight="bold"
                                    color={bgColor === "white" ? "dark" : "white"}
                                >
                                    {count}{" "}
                                    <WTypography variant="button" color={percentage.color} fontWeight="bold">
                                        {percentage.text}
                                    </WTypography>
                                </WTypography>
                            </WBox>
                        </Grid>
                        {direction === "right" ? (
                            <Grid item xs={4}>
                                <WBox
                                    variant="gradient"
                                    bgColor={bgColor === "white" ? icon.color : "white"}
                                    color={bgColor === "white" ? "white" : "dark"}
                                    width="3rem"
                                    height="3rem"
                                    marginLeft="auto"
                                    borderRadius="md"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    shadow="md"
                                >
                                    <Icon fontSize="small" color="inherit">
                                        {icon.component}
                                    </Icon>
                                </WBox>
                            </Grid>
                        ) : null}
                    </Grid>
                </WBox>
            </WBox>
        </Card>
    );
}

export default MiniStatisticsCard;
