import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostCode, CostCodes } from '../interfaces/costCodes';
import {
    createCostCode,
    fetchCostCodes,
    updateCostCode
} from './costCodesActions';

interface costCodesState {
    loading: boolean;
    error: any | null;
    costCodes: CostCodes;
};

export const initialState: costCodesState = {
    loading: false,
    error: null,
    costCodes: [],
};

const costCodesSlice = createSlice({
    name: 'costCodes',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchCostCodes.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchCostCodes.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.costCodes = action.payload.data;
        })
        builder.addCase(fetchCostCodes.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.costCodes = [];

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(createCostCode.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createCostCode.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(createCostCode.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(updateCostCode.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateCostCode.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(updateCostCode.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
} = costCodesSlice.actions;
export default costCodesSlice.reducer;
