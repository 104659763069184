import { createAsyncThunk } from '@reduxjs/toolkit';
import { abortRequests, getRejectedValues, useAxios } from '../utilities/axios';

export const login = createAsyncThunk('auth/login', async ({
    email,
    password
}: {
    email: string,
    password: string
}, thunk ) => {
    try {
        const axios = useAxios();
        const response = await axios.post(`${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}/api/v1/auth/login`, { email, password });

        return response.data;
    } catch (error: any) {
        return thunk.rejectWithValue(getRejectedValues(error));
    }
});

export const signup = createAsyncThunk('auth/signup', async ({
    first_name,
    last_name,
    email,
    phone,
    password,
    password_confirmation,
    smx_id,
    invitation,
}: {
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    password: string,
    password_confirmation: string,
    smx_id: string,
    invitation: string,
}, thunk ) => {
    try {
        const state: any = thunk.getState();
        const axios = useAxios(null, state.tenants.currentTenant);
        const response = await axios.post(`api/v1/auth/register`, {
            first_name,
            last_name,
            email,
            phone,
            password,
            password_confirmation,
            smx_id,
            invitation,
        });

        return response.data;
    } catch (error: any) {
        return thunk.rejectWithValue(getRejectedValues(error));
    }
});

export const refresh = createAsyncThunk('auth/refresh', async (_: void, thunk) => {
    try {
        const state: any = thunk.getState();
        if (!state.auth.accessToken) return;
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/auth/refresh');

        return response.data;
    } catch (error: any) {
        return thunk.rejectWithValue(getRejectedValues(error));
    }
});

export const claimOTACode = createAsyncThunk('auth/otacode/claim', async (data: any, thunk) => {
    try {
        const state: any = thunk.getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/auth/otacode/claim', data);

        return response.data;
    } catch (error: any) {
        return thunk.rejectWithValue(getRejectedValues(error));
    }
});

export const loginOTACode = createAsyncThunk('auth/otacode/login', async (data: any, thunk) => {
    try {
        const state: any = thunk.getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/auth/otacode/login', data);

        return response.data;
    } catch (error: any) {
        return thunk.rejectWithValue(getRejectedValues(error));
    }
});


export const logout = createAsyncThunk('auth/logout', async (_: void, thunk) => {
    try {
        const state: any = thunk.getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post(`api/v1/auth/logout`);

        return response.data;
    } catch (error: any) {
        return thunk.rejectWithValue(getRejectedValues(error));
    }
});

export const abortRequestAll = createAsyncThunk('requests/abort', async (_:void, thunk) => {
    try {
        abortRequests();

        return true;
    } catch (error: any) {
        return thunk.rejectWithValue(getRejectedValues(error));
    }
});