import { createContext, useContext, useReducer, useMemo, ReactNode } from 'react';

import PropTypes from 'prop-types';

const SitemaxUI = createContext<any>(null);

SitemaxUI.displayName = 'SitemaxUIContext';

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'MINI_SIDENAV': {
            return { ...state, miniSidenav: action.value };
        }
        case 'TRANSPARENT_NAVBAR': {
            return { ...state, transparentNavbar: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

type ContextProviderProps = {
    children?: ReactNode
}

function SitemaxUIControllerProvider({ children }: ContextProviderProps) {
    const initialState = {
        miniSidenav: true,
        transparentSidenav: false,
        transparentNavbar: false,
        // transparentSidenav: true,
        // transparentNavbar: true,
        openConfigurator: false,
        fixedNavbar: true,
        sidenavColor: 'primary',
        direction: 'ltr',
        layout: 'dashboard',
    };

    const [controller, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

    return <SitemaxUI.Provider value={value}> {children} </SitemaxUI.Provider>;
}

function useSitemaxUIController() {
    const context = useContext(SitemaxUI);

    if (!context) {
        throw new Error('useSoftUIController should be used inside the SoftUIControllerProvider.');
    }

    return context;
}

SitemaxUIControllerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch: any, value: any) => {
    dispatch({ type: 'MINI_SIDENAV', value })
};
const setTransparentNavbar = (dispatch: any, value: any) => {
    dispatch({ type: 'TRANSPARENT_NAVBAR', value });
};

export {
    SitemaxUIControllerProvider,
    useSitemaxUIController,
    setMiniSidenav,
    setTransparentNavbar,
};
