import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { claimOTACode, loginOTACode } from 'slices/authActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const ForgotPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const { error, loading } = useAppSelector((state) => state.auth);
    const [isCodeRequested, setIsCodeRequested] = useState(false);
    const [hasPhoneNumber, setHasPhoneNumber] = useState(false);
    const [dataCopy, setDataCopy] = useState(false);

    const onClaimOTACode = async (data: any) => {
        if (hasPhoneNumber) {
            delete data.email;
        } else {
            delete data.phone;
        }

        setDataCopy(data)
        dispatch(claimOTACode(data))
            .unwrap()
            .then(() => {
                setIsCodeRequested(true);
            }).catch((e) => {
                console.error(e);
            });
    };

    const onLoginOTACode = async (data: any) => {
        if (hasPhoneNumber) {
            delete data.email;
        } else {
            delete data.phone;
        }

        dispatch(loginOTACode(data))
            .unwrap()
            .then(() => {
                navigate('/');
                setIsCodeRequested(false);
            }).catch((e) => {
                console.error(e);
            });
    };

    const handleResend = () => {
        dispatch(claimOTACode(dataCopy));
    };

    const handleCancel = () => {
        setIsCodeRequested(false);
    };

    function ClaimSubtitle() {
        if (hasPhoneNumber) {
            return <Typography variant="subtitle1">Claim One-Time Password with Phone number</Typography>;
        }

        return <Typography variant="subtitle1">Claim One-Time Password with Email address</Typography>;
    };

    function HiddenTextField() {
        if (hasPhoneNumber) {
            return <input type="hidden" {...register("phone")} />;
        }

        return <input type="hidden" {...register("email")} />;
    };

    function ClaimTextField() {
        if (hasPhoneNumber) {
            return <>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    autoComplete="phone"
                    autoFocus
                    {...register("phone", { required: true, minLength: 9, maxLength: 15 })}
                    error={!!error?.errors?.phone}
                    helperText={error?.errors?.phone?.[0]}
                />
                <Box sx={{ mt: 1, textAlign: 'left' }}>
                    <Typography variant="subtitle2">or use <Link href="#" onClick={() => setHasPhoneNumber(false)}>Email address</Link></Typography>
                </Box>
            </>;
        }

        return <>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                autoFocus
                {...register("email", { required: true, minLength: 3, maxLength: 128 })}
                error={!!error?.errors?.email}
                helperText={error?.errors?.email?.[0]}
            />
            <Box sx={{ mt: 1, textAlign: 'left' }}>
                <Typography variant="subtitle2">or use <Link href="#" onClick={() => setHasPhoneNumber(true)}>Phone number</Link></Typography>
            </Box>
        </>;
    };

    function ForgotForm() {
        if (isCodeRequested) {
            return <>
                <Stack
                    direction="column"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ textAlign: 'center' }}
                >
                    <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>Sitemax</Typography>
                    <Typography variant="subtitle1">Sign in with One-Time Password</Typography>
                </Stack>
                <Box
                    component="form"
                    onSubmit={handleSubmit(onLoginOTACode)}
                    sx={{ mt: 3 }}
                >
                    <HiddenTextField />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="One-Time Password"
                        type="password"
                        id="otacode"
                        autoComplete="otacode"
                        {...register("otacode", { required: true, minLength: 4, maxLength: 8 })}
                        error={!!error?.errors?.otacode}
                        helperText={error?.errors?.otacode?.[0]}
                    />
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 3, mb: 3 }}
                    >
                        Sign In
                    </LoadingButton>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="subtitle2">Didn't receive the code?<br />
                        <Link href="#" onClick={handleResend}>Resend code</Link>&nbsp;or&nbsp;
                        <Link href="#" onClick={handleCancel}>Change {hasPhoneNumber ? 'phone number' : 'email address'}</Link><br />
                        and try again
                    </Typography>
                </Box>
            </>;
        }

        return <>
            <Stack
                direction="column"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                sx={{ textAlign: 'center' }}
            >
                <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>Sitemax</Typography>
                <ClaimSubtitle />
            </Stack>
            <Box
                component="form"
                onSubmit={handleSubmit(onClaimOTACode)}
                sx={{ mt: 3 }}
            >
                <ClaimTextField />
                <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 3, mb: 3 }}
                >
                    Submit
                </LoadingButton>
            </Box>
        </>;
    };

    return <>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Container component="main" maxWidth="xs">
                <Paper elevation={2} sx={{ py: 6, px: 4 }}>
                    <ForgotForm />
                    <Box sx={{ mt: 1, textAlign: 'center' }}>
                        <Link href="/signin">Go back to Sign In</Link>
                    </Box>
                </Paper>
            </Container>
        </Grid>
    </>;
};

export default ForgotPage;