import axios, {AxiosInstance} from 'axios';
import { Team } from 'interfaces/teams';
import { Tenant } from 'interfaces/tenants';
import { REQUEST_CODES } from './constants';
import { isSubdomain } from './helpers';

const instance = axios.create();
let controllers: any[] = [];

export function getAppDomain(): string | undefined {
    return `${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_PORT}`;
};

export function getSubdomain(withProtocol: boolean = false): string | null {
    const locationHost: string = window.location.hostname;
    const appDomain: string | undefined = process.env.REACT_APP_DOMAIN;

    if (isSubdomain()) {
        if (!appDomain) return null;
        if (locationHost.indexOf(appDomain) <= 0) return null;
    }

    if (withProtocol) {
        return `${window.location.protocol}//${locationHost}`;
    }

    return locationHost;
};

export function getTenant(): string | null {
    const searchParams = new URLSearchParams(window.location.search);

    let tenant: string | null = null;
    if (localStorage.getItem('currentTenant')) tenant = localStorage.getItem('currentTenant');
    if (searchParams.get('tenant')) tenant = searchParams.get('tenant');

    return tenant;
};

export function getAccessToken(): string | null {
    const searchParams = new URLSearchParams(window.location.search);
    const token: string | null = searchParams.get('token');

    if (token) {
        localStorage.setItem('accessToken', token);

        return token;
    }

    return localStorage.getItem('accessToken');
};

export function getURLSearchParam(param: string): string | null {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get(param);

    return paramValue ? decodeURI(paramValue) : paramValue;
};

function redirectTo(url: string, isBlank: boolean): void {
    if (isBlank) {
        window.open(url, '_blank');
    } else {
        window.location.replace(url);
    }
};

export function redirectToCentral(route: null | string, withToken: boolean, isBlank: boolean): void {
    let url = `${window.location.protocol}//${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_PORT}`;
    if (route) {
        url = `${url}/${route}`;
    }
    if (withToken) {
        const token = getAccessToken();
        url = `${url}/redirect/?token=${token}`;
    }

    redirectTo(url, isBlank);
};

export function redirectToTenant(tenant: Tenant, isBlank: boolean): void {
    const token = getAccessToken();
    if (!token) return;

    const url = `${window.location.protocol}//${tenant.domain}/redirect/?token=${token}`;

    redirectTo(url, isBlank);
};

export function redirectToHost(host: string, isBlank: boolean): void {
    const token = getAccessToken();
    if (!token) return;

    const url = `${host}:${process.env.REACT_APP_PORT}/redirect/?token=${token}`;

    redirectTo(url, isBlank);
};

export function getRejectedValues(error: any): any {
    if (error.code === REQUEST_CODES.CANCELED) {
        return {
            status: REQUEST_CODES.CANCELED,
            data: {
                message: error.message,
            },
        };
    }

    return {
        status: error.response.status,
        data: error.response.data,
    };
};

export function useAxios(token?: string | null, tenant?: Tenant | null, team?: Team | null, abortOnNavigate: boolean = false): AxiosInstance {
    if (token) {
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
        delete(instance.defaults.headers.common['Authorization']);
    }

    if (team) {
        instance.defaults.headers.common['X-Tenant-Team'] = team.id;
    } else {
        delete(instance.defaults.headers.common['X-Tenant-Team']);
    }

    let port =  process.env.REACT_APP_API_PORT
        ? `:${process.env.REACT_APP_API_PORT}`
        : '';

    if (tenant) {
        /**
         * Nginx routes don't support {tenant}/api paths. Solve nginx config challenge before use that.
         *
         * if (hasSubdomain()) {
         *     instance.defaults.baseURL = `${window.location.protocol}//${tenant.domain}`;
         * } else {
         *     instance.defaults.baseURL = `${window.location.protocol}//${process.env.REACT_API_DOMAIN}/${tenant.name}`;
         * }
         */
        instance.defaults.baseURL = `${window.location.protocol}//${tenant.domain}${port}`;
    } else {
        instance.defaults.baseURL = `${window.location.protocol}//${process.env.REACT_APP_API_DOMAIN}${port}`;
    }

    // if (abortOnNavigate) {
    //     const controller = makeAbortController();
    //     instance.defaults.signal = controller.signal;
    // }

    return instance;
};

export function makeAbortController(): any {

    const controller = new AbortController();
    controllers.push(controller);

    return controller;
};

export function abortRequests(): void {
    controllers.map((controller: any) => {
        controller?.abort();
    });

    controllers = [];
};
