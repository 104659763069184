import { forwardRef } from 'react';
import WTypographyRoot from 'containers/WTypography/WTypographyRoot';

const SoftTypography = forwardRef(({ color = 'dark', fontWeight = false, textTransform = 'none', verticalAlign = 'unset', textGradient = false, opacity = 1, children, ...rest }: any, ref) => (
    <WTypographyRoot
        {...rest}
        ref={ref}
        ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient }}
    >
        {children}
    </WTypographyRoot>
));

export default SoftTypography;
