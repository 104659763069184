import {
    forwardRef,
    useEffect,
    useRef,
    useState
} from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useLocation } from 'react-router-dom';
import { deleteDivision, fetchDivisions } from 'modules/settings/slices/divisionsActions';
import { Division, Divisions } from 'modules/settings/interfaces/divisions';
import { BaseModalRef } from 'modals/BaseModal';
import { Button, List, ListItem, ListItemIcon } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import DivisionModal from '../DivisionModal/DivisionModal';
import WTypography from 'containers/WTypography';
import { SitemaxTable } from 'containers/SitemaxTable/SitemaxTable';

export const labelDivisionsTab = 'Divisions';

const DivisionsTab = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { divisions, loading, error } = useAppSelector((state) => state.divisions);
    const divisionModal = useRef<BaseModalRef>(null);

    const [items, setItems] = useState<Divisions>([]);

    useEffect(() => {
        dispatch(fetchDivisions(null));
    }, [location, currentTenant]);

    useEffect(() => {
        if (!divisions) return;

        setItems(divisions);
    }, [divisions]);

    const handleCreate = () => {
        dispatch(fetchDivisions(null));
    };

    const handleDelete = (row: any) => {
        dispatch(deleteDivision(row.id));
        dispatch(fetchDivisions(null));
    };

    const headers = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params: { row?: Division }) => {
                return <><WTypography sx={{ pl: 2 }}>{params.row?.name}</WTypography></>;
            },
        },
        {
            field: 'number',
            headerName: 'Number',
            flex: 1,
            renderCell: (params: { row?: Division }) => {
                return <><WTypography sx={{ pl: 2 }}>{params.row?.number}</WTypography></>;
            },
        },
        {
            actions: [
                {
                    title: 'Edit',
                    onClick: (row: any) => {
                        divisionModal?.current?.open(row);
                    },
                    icon: <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>,
                },
                {
                    title: 'Delete',
                    onClick: (row: any) => {
                        handleDelete(row);
                    },
                    icon: <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>,
                },
            ],
        },
    ];

    return <>
        <Button
            variant="contained"
            disableElevation
            onClick={() => divisionModal?.current?.open()}
            startIcon={<Add />}
            sx={{ marginBottom: 2 }}
        >
            Create Division
        </Button>
        <SitemaxTable
            headers={headers}
            rows={divisions}
            getData={() => {
                dispatch(fetchDivisions(null));
            }}
            loading={loading}
        ></SitemaxTable>
        <DivisionModal
            ref={divisionModal}
            onSubmit={handleCreate}
        ></DivisionModal>
    </>;
};

export default forwardRef(DivisionsTab);
