import {
    Add as AddIcon
} from '@mui/icons-material';
import {
    Autocomplete,
    Avatar,
    Checkbox,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    TextField
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import {
    useEffect,
    useMemo,
    useState
} from 'react';


export interface UsersAutocompleteProps {
    label: string,
    error?: boolean,
    helperText?: string,
    dependent?: any,
    valueSet?: any[],
    debounceOnOpen?: boolean,
    onSelect: (value: any) => void,
    onDebounce: (value: string, callback: (value: any) => void) => void,
    loading?: boolean,
};

const UsersAutocomplete = (
    props: UsersAutocompleteProps & {
        [prop: string]: any;
    }
) => {
    let {
        label,
        error,
        helperText,
        dependent,
        valueSet,
        debounceOnOpen,
        onDebounce,
        onSelect,
        loading = false,
        ...autocompleteProps
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<any[]>([]);
    const [value, setValue] = useState<any>([]);
    const [inputValue, setInputValue] = useState<any>('');

    const debounceInputChange = useMemo(() => {
        return debounce((newValue: string) => {
            onDebounce(newValue, (values: any[]) => {
                setOptions(values);
                if (newValue.length) setOpen(true);
            });
        }, 600);
    }, []);

    useEffect(() => {
        if (!dependent) {
            setValue([]);
            setInputValue('');
        }
        debounceInputChange('');
    }, [dependent]);

    return <Autocomplete
        multiple
        disabled={loading}
        clearOnBlur={false}
        clearOnEscape={false}
        fullWidth
        disableCloseOnSelect
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        noOptionsText="No options found..."
        options={options}
        filterOptions={(options: any) => options}
        getOptionLabel={(option: any) => option?.email ? option?.email : option?.phone}
        isOptionEqualToValue={(option: any, value: any) => {
            return option.id == value.id;
        }}
        renderOption={(props: any, option: any, { selected }) => (
            option?.name ? <ListItem key={option.id} {...props}>
                <ListItemIcon>
                    <Checkbox
                        checked={selected}
                    />
                </ListItemIcon>
                {option?.initials && <ListItemAvatar>
                    <Avatar>{option?.initials}</Avatar>
                </ListItemAvatar>}
                <ListItemText
                    primary={option?.name}
                    secondary={[option?.email, option?.phone].filter(item => item).join(', ')}
                />
            </ListItem> : <ListItem key={option.id} {...props}>
                <ListItemAvatar>
                    <Avatar><AddIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={[option?.email, option?.phone].filter(item => item).join(', ')}
                />
            </ListItem>
        )}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                error={error}
                helperText={helperText}
            />
        )}
        onChange={(event: any, newValue: any) => {
            onSelect(newValue);
            setValue(newValue);
        }}
        onInputChange={(event: any, newValue: any) => {
            setInputValue(newValue);

            if (!newValue) {
                return;
            }
            debounceInputChange(newValue);
        }}
        value={valueSet ?? value}
        inputValue={inputValue}
        {...autocompleteProps}
    />;
};

export default UsersAutocomplete;