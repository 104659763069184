import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from '../interfaces/projects';
import {
    createProject
} from './projectsActions';

interface ProjectState {
    loading: boolean;
    error: any | null;
    project: Project | null;
    currentProject: Project | null,
};

export const initialState: ProjectState = {
    loading: false,
    error: null,
    project: null,
    currentProject: null,
};

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createProject.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createProject.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.project = action.payload.data;
        })
        builder.addCase(createProject.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })
    }
});

export const {
    reset,
} = projectSlice.actions;
export default projectSlice.reducer;
