import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    deleteDocumentVersion,
    fetchDocument,
    fetchDocumentVersions,
    fetchMarkup,
    renameDocument,
    saveMarkup,
    setDocumentVersion,
    updateDocument
} from './documentsActions';

import {
    Document,
    Markup
} from '../interfaces/documents';

interface DocumentState {
    document: Document | null;
    markup: Markup | null;
    shareable: any | null;
    versions: any[];
    loading: boolean;
    error: any | null;
};

export const initialState: DocumentState = {
    document: null,
    markup: null,
    shareable: null,
    versions: [],
    loading: false,
    error: null
};

const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        reset: () => initialState,
        setDocument: (state, action: PayloadAction<Document>) => {
            state.document = action.payload;
        },
        clearDocument: (state) => {
            state.document = null;
        },
        clearMarkup: (state) => {
            state.markup = null;

            if (!state.document) return;
            state.document.has_markup = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDocument.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDocument.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.document = action.payload?.data;
        })
        builder.addCase(fetchDocument.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
        builder.addCase(fetchMarkup.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchMarkup.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.markup = action.payload.data;
        })
        builder.addCase(fetchMarkup.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
        builder.addCase(updateDocument.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateDocument.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.document = action.payload.data;
        })
        builder.addCase(updateDocument.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
        builder.addCase(saveMarkup.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(saveMarkup.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(saveMarkup.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
        builder.addCase(renameDocument.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(renameDocument.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.document = action.payload.data;
        })
        builder.addCase(renameDocument.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(fetchDocumentVersions.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDocumentVersions.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (!action.payload) return;

            state.versions = action.payload.data;
        })
        builder.addCase(fetchDocumentVersions.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
        builder.addCase(setDocumentVersion.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(setDocumentVersion.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(setDocumentVersion.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })

        builder.addCase(deleteDocumentVersion.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(deleteDocumentVersion.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(deleteDocumentVersion.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
    }
});

export const { reset, clearMarkup, setDocument, clearDocument } = documentSlice.actions;
export default documentSlice.reducer;