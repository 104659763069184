import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Classification, Classifications } from '../interfaces/classifications';
import {
    createClassification,
    fetchClassifications,
    updateClassification
} from './classificationsActions';

interface classificationsState {
    loading: boolean;
    error: any | null;
    classifications: Classifications;
};

export const initialState: classificationsState = {
    loading: false,
    error: null,
    classifications: [],
};

const classificationsSlice = createSlice({
    name: 'classifications',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchClassifications.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchClassifications.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.classifications = action.payload.data;
        })
        builder.addCase(fetchClassifications.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.classifications = [];

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(createClassification.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createClassification.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(createClassification.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(updateClassification.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateClassification.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(updateClassification.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
} = classificationsSlice.actions;
export default classificationsSlice.reducer;
