import {
    forwardRef,
    useEffect,
    useRef,
    useState
} from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useLocation } from 'react-router-dom';
import { deletePhase, fetchPhases } from 'modules/settings/slices/phasesActions';
import { Phase, Phases } from 'modules/settings/interfaces/phases';
import { BaseModalRef } from 'modals/BaseModal';
import PhaseModal from '../PhaseModal/PhaseModal';
import { Button, ListItemIcon } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import WTypography from 'containers/WTypography';
import { SitemaxTable } from 'containers/SitemaxTable/SitemaxTable';

export const labelPhasesTab = 'Phases';

const PhasesTab = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { phases, loading, error } = useAppSelector((state) => state.phases);
    const phaseModal = useRef<BaseModalRef>(null);

    useEffect(() => {
        dispatch(fetchPhases(null));
    }, [location, currentTenant]);

    const handleCreate = () => {
        dispatch(fetchPhases(null));
    };

    const handleDelete = (row: any) => {
        dispatch(deletePhase(row.id));
        dispatch(fetchPhases(null));
    };

    const headers = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params: { row?: Phase }) => {
                return <><WTypography sx={{ pl: 2 }}>{params.row?.name}</WTypography></>;
            },
        },
        {
            field: 'code',
            headerName: 'Code',
            flex: 1,
            renderCell: (params: { row?: Phase }) => {
                return <><WTypography sx={{ pl: 2 }}>{params.row?.code}</WTypography></>;
            },
        },
        {
            actions: [
                {
                    title: 'Edit',
                    onClick: (row: any) => {
                        phaseModal?.current?.open(row);
                    },
                    icon: <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>,
                },
                {
                    title: 'Delete',
                    onClick: (row: any) => {
                        handleDelete(row);
                    },
                    icon: <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>,
                },
            ],
        },
    ];

    return <>
        <Button
            variant="contained"
            disableElevation
            onClick={() => phaseModal?.current?.open()}
            startIcon={<Add />}
            sx={{ marginBottom: 2 }}
        >
            Create Phase
        </Button>
        <SitemaxTable
            headers={headers}
            rows={phases}
            getData={() => {
                dispatch(fetchPhases(null));
            }}
            loading={loading}
        ></SitemaxTable>
        <PhaseModal
            ref={phaseModal}
            onSubmit={handleCreate}
        ></PhaseModal>
    </>;
};

export default forwardRef(PhasesTab);
