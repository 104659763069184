import {
    Add
} from '@mui/icons-material';
import {
    Alert,
    Button,
    LinearProgress,
    List,
    ListItemButton,
    ListItemText
} from '@mui/material';
import { Tenant } from 'interfaces/tenants';
import { BaseModalRef } from 'modals/BaseModal';
import TenantCreateModal from 'modals/TenantCreateModal';
import { useEffect, useRef } from 'react';
import {
    useNavigate
} from 'react-router-dom';
import { fetchTenants } from 'slices/tenantsActions';
import { resetCurrentTenant, setCurrentTenant } from 'slices/tenantsSlice';
import { getAppDomain } from 'utilities/axios';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';


const TenantsList = ({
    onClick,
}: {
    onClick?: (tenant: Tenant) => void,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { tenants, currentTenant, loading } = useAppSelector((state) => state.tenants);
    const centralDomain = getAppDomain();
    const tenantCreateModal = useRef<BaseModalRef>(null);

    const handleTenantSwitch = (tenant: Tenant) => {
        dispatch(setCurrentTenant(tenant));
        navigate('/documents');
        onClick && onClick(tenant);
    };

    const handleCentral = () => {
        dispatch(resetCurrentTenant());
        navigate('/documents/shared');
    };

    const handleAddWorkspace = () => {
        tenantCreateModal?.current?.open();
    };

    if (tenants?.length === 0) {
        return <>
            <List>
                <Button
                    variant="text"
                    startIcon={<Add />}
                    fullWidth
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        handleAddWorkspace();
                    }}
                    sx={{mb: 1}}
                >
                    Add Workspace
                </Button>
                {loading && <LinearProgress sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%"

                }}/>}
                <Alert
                    variant="outlined"
                    severity="info"
                    sx={{m: 1}}
                >
                    No Workspaces Available
                </Alert>
            </List>
            <TenantCreateModal
                ref={tenantCreateModal}
                onSubmit={() => {

                }}
            ></TenantCreateModal>
        </>
    }

    return <>
        <List>
            <Button
                variant="text"
                startIcon={<Add />}
                fullWidth
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    handleAddWorkspace();
                }}
                sx={{mb: 1}}
            >
                Add Workspace
            </Button>
            {loading && <LinearProgress sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%"

            }}/>}
            {/* <ListItemButton
                    sx={{px: 3}}
                    onClick={handleCentral}
                    selected={!currentTenant}
                >
                <ListItemText primary={'Central'} secondary={centralDomain} />
            </ListItemButton> */}
            {tenants && tenants.map((item) => (
                <ListItemButton
                    key={item.name}
                    sx={{px: 3}}
                    onClick={() => handleTenantSwitch(item)}
                    selected={!!(currentTenant && (item.id === currentTenant.id))}
                >
                    <ListItemText primary={item.title} secondary={item.domain} />
                </ListItemButton>
            ))}
        </List>
        <TenantCreateModal
            ref={tenantCreateModal}
            onSubmit={() => {

            }}
        ></TenantCreateModal>
    </>;
};

export default TenantsList;