import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Skeleton,
    TextField
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { CostCode } from 'modules/settings/interfaces/costCodes';
import { createCostCode, updateCostCode } from 'modules/settings/slices/costCodesActions';

export interface CostCodeModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const CostCodeModal = (
    props: CostCodeModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.costCodes);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [costCode, setCostCode] = useState<CostCode | undefined>(undefined);
    const formId = 'costCode_create_form_' + nanoid();

    useEffect(() => {
        if (isOpen) {
            reset(costCode);
        }
    }, [isOpen]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const onSubmit = (data: any) => {
        if (costCode && costCode.id) {
            data.id = costCode.id;
            dispatch(updateCostCode(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        } else {
            dispatch(createCostCode(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        }
    };

    const open = (costCode: CostCode | undefined = undefined) => {
        if (costCode) {
            setCostCode(costCode);
        } else {
            setCostCode({
                name: '',
                code: '',
            });
        }
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: (costCode?: CostCode | undefined) => open(costCode),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={'Create cost code'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        { costCode && costCode.id ? 'Update' : 'Create' }
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        id="name"
                        variant="outlined"
                        label="Name"
                        {...register("name")}
                        error={!!error?.errors?.name}
                        helperText={error?.errors?.name?.[0]}
                        autoFocus
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        id="code"
                        variant="outlined"
                        label="Code"
                        {...register("code")}
                        error={!!error?.errors?.code}
                        helperText={error?.errors?.code?.[0]}
                        autoFocus
                        fullWidth
                        required
                    />
                </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(CostCodeModal);
