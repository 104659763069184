import { AppBarProps } from '@mui/material/AppBar';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { AppBarClasses } from '@mui/material/AppBar';
import { Theme } from '@mui/material/styles';

interface AppBarConfig {
    defaultProps?: Partial<AppBarProps>;
    styleOverrides?: Partial<OverridesStyleRules<keyof AppBarClasses, 'MuiAppBar', Omit<Theme, 'components'>>>;
}

const appBar: AppBarConfig = {
    defaultProps: {
        color: 'transparent',
    },

    styleOverrides: {
        root: {
            boxShadow: 'none',
        },
    },
};

export default appBar;