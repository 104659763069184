import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ProjectSettingsPage = () => {
    return <Content>
        Project Settings
    </Content>;
}

export default ProjectSettingsPage;