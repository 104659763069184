import Container from '@mui/material/Container';
import {
    useEffect
} from 'react';
import {
    Outlet,
    useLocation
} from 'react-router-dom';
import {
    getSubdomain,
    redirectToCentral
} from 'utilities/axios';
import { isSubdomain } from 'utilities/helpers';

const UnauthorizedRoute = () => {
    const location = useLocation();
    const subdomain = getSubdomain(true);

    // DEPRECATED: App doesn't support main domain anymore
    // useEffect(() => {
    //     if (subdomain) {
    //         redirectToCentral(`signin/?redirect=${encodeURI(subdomain)}`, false, false);
    //     }
    // }, [location]);

    // if (subdomain) {
    //     return <></>;
    // }

    if (isSubdomain()) {
        return <>
            <Container component="main" disableGutters>
                <Outlet />
            </Container>
        </>;
    } else {
        return <>
            <Container component="main" disableGutters sx={{ textAlign: 'center', paddingTop: '20px' }}>
                Please use the subdomain to access the application.
            </Container>
        </>;
    }
};

export default UnauthorizedRoute;