import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project, Projects } from '../interfaces/projects';
import {
    assignUsers,
    fetchProject,
    fetchProjects,
    fetchUsers,
    unassignUsers
} from './projectsActions';
import { Users } from 'interfaces/users';

interface ProjectsState {
    loading: boolean;
    error: any | null;
    projects: Projects | null;
    currentProject: Project | null;
    users: Users | null;
};

export const initialState: ProjectsState = {
    loading: false,
    error: null,
    projects: [],
    currentProject: null,
    users: [],
};

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        reset: () => initialState,
        setCurrentProjectById: (state, action: PayloadAction<string>) => {
            if (!state.projects) return;
            const project = state.projects?.find((project: Project) => {
                return project.id === action.payload;
            });

            if (project) {
                state.currentProject = project;
            }
        },
        setCurrentProject: (state, action: PayloadAction<Project | null>) => {
            state.currentProject = action.payload;
        },
        resetCurrentProject: (state) => {
            state.currentProject = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchProjects.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchProjects.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.projects = action.payload.data;
        })
        builder.addCase(fetchProjects.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.projects = [];

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(fetchProject.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchProject.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.currentProject = action.payload.data;
        })
        builder.addCase(fetchProject.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(fetchUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.users = action.payload.data;
        })
        builder.addCase(fetchUsers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(assignUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(assignUsers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.users = action.payload.data;
        })
        builder.addCase(assignUsers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })

        builder.addCase(unassignUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(unassignUsers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.users = action.payload.data;
        })
        builder.addCase(unassignUsers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
    setCurrentProjectById,
    setCurrentProject,
    resetCurrentProject,
} = projectsSlice.actions;
export default projectsSlice.reducer;
