import React, {useEffect, useState} from 'react';
import {Radio, RadioChangeEvent} from 'antd';
import {useAppDispatch} from '../../../../utilities/hooks';
import {setterSchedule} from '../../../../slices/scheduleSlice';
import {SchedulePageViewType, ScheduleViewType} from '../../../../types';
import {CalendarScaleType} from 'smx-components';

const options: {label: string, value: SchedulePageViewType}[] = [
    { label: 'List', value: 'list' },
    { label: 'Timeline', value: 'timeline' },
    { label: 'Schedule', value: 'schedule' },
    { label: 'Board', value: 'board' },
];

const ButtonScale = () => {
    const dispatch = useAppDispatch();
    const [schedulePageView, setSchedulePageView] = useState<SchedulePageViewType>('timeline');

    const onChangeScale = ({ target: { value } }: RadioChangeEvent) => {
        setSchedulePageView(value);
    };

    useEffect(() => {
        let view: ScheduleViewType = 'calendar'
        let activeCalendarPage: CalendarScaleType = 'list';

        if(schedulePageView === 'timeline'){
            view = 'gant';
        }

        if(schedulePageView === 'schedule'){
            activeCalendarPage = 'day';
        }

        if(schedulePageView === 'board'){
            activeCalendarPage = 'agenda';
        }

        dispatch(setterSchedule({activeCalendarPage, view}));

    }, [schedulePageView]);

    return (
        <Radio.Group options={options} onChange={onChangeScale} value={schedulePageView} optionType="button" />
    );
};

export default ButtonScale;
