import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Skeleton,
    TextField
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { createUser, updateUser } from 'slices/usersActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

interface User {
    id?: string;
    first_name: string;
    last_name: string;
    password: string;
    password_confirmation: string;
    phone: string;
};
export interface UserModalProps {
    parent?: string;
    onSubmit?: () => void;
    onClose?: () => void;
};

const UserModal = (
    props: UserModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.users);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState<User | undefined>(undefined);
    const formId = 'user_create_form_' + nanoid();

    useEffect(() => {
        if (isOpen) {
            reset(user);
        }
    }, [isOpen]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const onSubmit = (data: any) => {
        if (props.parent) data.parent = props.parent;

        if (user && user.id) {
            data.id = user.id;
            dispatch(updateUser(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        } else {
            dispatch(createUser(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        }
    };

    const open = (user: User | undefined = undefined) => {
        if (user) {
            setUser(user);
        } else {
            reset(user);
        }
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: (user?: User | undefined) => open(user),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={'Create user'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        { user && user.id ? 'Update' : 'Create' }
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        id="first_name"
                        variant="outlined"
                        label="First Name"
                        {...register("first_name")}
                        error={!!error?.errors?.first_name}
                        helperText={error?.errors?.first_name?.[0]}
                        autoFocus
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        id="last_name"
                        variant="outlined"
                        label="Last Name"
                        {...register("last_name")}
                        error={!!error?.errors?.last_name}
                        helperText={error?.errors?.last_name?.[0]}
                        autoFocus
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        id="email"
                        variant="outlined"
                        margin="normal"
                        label="Email"
                        {...register("email")}
                        error={!!error?.errors?.email}
                        helperText={error?.errors?.email?.[0]}
                        autoFocus
                        fullWidth
                        required
                        autoComplete="email"
                        {...register("email", { required: true, minLength: 3, maxLength: 128 })}

                    />
                    <TextField
                        id="password"
                        variant="outlined"
                        margin="normal"
                        label="Password"
                        type="password"
                        {...register("password")}
                        error={!!error?.errors?.password}
                        helperText={error?.errors?.password?.[0]}
                        autoFocus
                        fullWidth
                        required
                        {...register("password", { required: true, minLength: 3, maxLength: 128 })}

                    />
                    <TextField
                        id="password_confirmation"
                        variant="outlined"
                        margin="normal"
                        label="Verify Password"
                        type="password"
                        {...register("password_confirmation")}
                        error={!!error?.errors?.password_confirmation}
                        helperText={error?.errors?.password_confirmation?.[0]}
                        autoFocus
                        fullWidth
                        required
                        {...register("password_confirmation", { required: true, minLength: 3, maxLength: 128 })}

                    />
                    <TextField
                        id="phone"
                        variant="outlined"
                        margin="normal"
                        label="Phone"
                        type="phone"
                        {...register("phone")}
                        error={!!error?.errors?.phone}
                        helperText={error?.errors?.phone?.[0]}
                        autoFocus
                        fullWidth
                        required
                        {...register("phone", { required: true, minLength: 3, maxLength: 12 })}

                    />


                </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(UserModal);